import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import ReactTooltip from 'react-tooltip';
import ReactDOMClient from 'react-dom/client';

import './index.scss';

// ====================================================================

class Battleground {
  constructor(width, height, adjustCoordinates, imageName, updateHighResolutionMinimapCanvas, drawCanvas, updateMinimapDimensions, phasesDefaultValues, specialNormalModes) {
    this.width = width;
    this.height = height;
    this.adjustCoordinates = adjustCoordinates;
    this.radius = 0.5 * Math.sqrt(this.width ** 2 + this.height ** 2) + 50.0;
    this.minimap = new Minimap(imageName, [8, 8], 2, updateHighResolutionMinimapCanvas, drawCanvas, updateMinimapDimensions);
    this.normalizedRadius = this.radius / this.width;
    this.phasesDefaultValues = phasesDefaultValues;
    this.specialNormalModes = specialNormalModes;
  }
}

// --------------------------------------------------------------------

class BlueZoneElucidator extends React.Component {
  constructor(props) {
    super(props);
    this.battleground = React.createRef();
    this.battlegrounds = [];
    this.battlegroundErrorSpaces = [];
    this.battlegroundFixedEndFieldCircles = [];
    this.battlegroundFixedEndMountainCircles = [];
    this.battlegroundFixedEndTownCircles = [];
    this.battlegroundIntenseBattleRoyales = [];
    this.battlegroundPasses = [];
    this.battlegroundTeamDeathmatches = [];
    this.battleScale = React.createRef();
    this.circleCenterCoordinate = React.createRef();
    this.clientMouseX = 0;
    this.clientMouseY = 0;
    this.ctrlAltClickCoordinateX = 0.0;
    this.ctrlAltClickCoordinateY = 0.0;
    this.ctrlAltDragCoordinateX = 0.0;
    this.ctrlAltDragCoordinateY = 0.0; 
    this.errorSpaces = [];
    this.fixedEndFieldCircles = [];
    this.fixedEndMountainCircles = [];
    this.fixedEndTownCircles = [];
    this.highResolutionMinimapCanvas = React.createRef();
    this.intenseBattleRoyales = [];
    this.interactionInformationDiv = React.createRef();
    this.isTouchDevice = false;
    this.maximumMinimapScale = 16.0;
    this.minimapCanvas = React.createRef();
    this.minimapOffsetX = 0;
    this.minimapOffsetY = 0;
    this.minimapMouseHover = false;
    this.minimapMouseX = 0;
    this.minimapMouseY = 0;
    this.minimapPadding = React.createRef();
    this.minimapScale = 1.0;
    this.minimapSpacer = React.createRef();
    this.mode = React.createRef();
    this.mounted = false;
    this.mouseDown = false;
    this.mouseDownCtrlAltKey = false;
    this.mouseDownShiftKey = false;
    this.mouseDownMinimapOffsetX = 0;
    this.mouseDownMinimapOffsetY = 0;
    this.mouseDownMinimapMouseX = 0;
    this.mouseDownMinimapMouseY = 0;
    this.mouseMoveCoordinatesLabel = "";
    this.mouseMoveCoordinateX = 0;
    this.mouseMoveCoordinateY = 0;
    this.passes = [];
    this.phases = [React.createRef(),
                   React.createRef(),
                   React.createRef(),
                   React.createRef(),
                   React.createRef(),
                   React.createRef(),
                   React.createRef(),
                   React.createRef(),
                   React.createRef()];
    this.phasesValues = React.createRef();
    this.pinchPreviousMinimapCenterX = 0;
    this.pinchPreviousMinimapCenterY = 0;
    this.pinchStartMinimapDistance = 0;
    this.pinchStartMinimapScale = 1.0;
    this.settings = React.createRef();
    this.shape = React.createRef();
    this.showErrorSpaces = React.createRef();
    this.showFixedEndFieldCircles = React.createRef();
    this.showFixedEndMountainCircles = React.createRef();
    this.showFixedEndTownCircles = React.createRef();
    this.showPasses = React.createRef();
    this.shiftClickCoordinateX = 0.0;
    this.shiftClickCoordinateY = 0.0;
    this.shiftDragCoordinateX = 0.0;
    this.shiftDragCoordinateY = 0.0;
    this.squareCoordinates = React.createRef();
    this.teamDeathmatches = [];
    this.totalRoundTimeString = "0m0s";
    this.touchCoordinateX = 0.0;
    this.touchCoordinateY = 0.0;
    this.touchRecentlyEnded = false;
    this.touchRecentlyEndedTimeoutID = undefined;
    this.touchRecentlyMoved = false;
    this.touchRecentlyMovedTimeoutID = undefined;
    this.touchStartCoordinateX = 0.0;
    this.touchStartCoordinateY = 0.0;
    this.touchStartMinimapOffsetX = 0;
    this.touchStartMinimapOffsetY = 0;
    this.touchStartMinimapTouchX = 0;
    this.touchStartMinimapTouchY = 0;
    this.touchStartSquareCorner = false;
    this.userAgentIsSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.wheelTimeoutID = undefined;
  }

  battlegroundMaximumMinimapScale(battlegroundName) {
    let battlegroundSize = Math.max(this.battlegrounds[battlegroundName].width, this.battlegrounds[battlegroundName].height);

    if (isNaN(battlegroundSize)) { return 16.0; }

    let maximumMinimapScale = 4.0;

    while (battlegroundSize > 127.5 * maximumMinimapScale) {
      maximumMinimapScale += this.minimapScaleIncrement(maximumMinimapScale);
    }

    return maximumMinimapScale;
  }

  clearTouchRecentlyEndedTimeout(blueZoneElucidator) {
    blueZoneElucidator.touchRecentlyEnded = false;
  }

  clearTouchRecentlyMovedTimeout(blueZoneElucidator) {
    blueZoneElucidator.touchRecentlyMoved = false;
  }

  clipFixedEndFieldCircles() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let clipPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndFieldCircles.length; i++) {
      clipPath.addPath(this.battlegroundFixedEndFieldCircles[i].path, matrix);
    }

    return clipPath;
  }

  clipFixedEndMountainCircles() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let clipPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndMountainCircles.length; i++) {
      clipPath.addPath(this.battlegroundFixedEndMountainCircles[i].path, matrix);
    }

    return clipPath;
  }

  clipFixedEndTownCircles() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let clipPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndTownCircles.length; i++) {
      clipPath.addPath(this.battlegroundFixedEndTownCircles[i].path, matrix);
    }

    return clipPath;
  }

  clipIntenseBattleRoyales() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

    context.beginPath();

    for (let i = 0; i < this.battlegroundIntenseBattleRoyales.length; i++) {
      context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundIntenseBattleRoyales[i].coordinate[0] + this.battlegroundIntenseBattleRoyales[i].normalizedRadius),
                     this.convertMapToCanvasCoordinateY(this.battlegroundIntenseBattleRoyales[i].coordinate[1]));

      context.arc(this.convertMapToCanvasCoordinateX(this.battlegroundIntenseBattleRoyales[i].coordinate[0]),
                  this.convertMapToCanvasCoordinateY(this.battlegroundIntenseBattleRoyales[i].coordinate[1]),
                  this.minimapScale * this.battlegroundIntenseBattleRoyales[i].normalizedRadius * this.minimapCanvas.current.width,
                  0, 2 * Math.PI);
    }

    context.closePath();

    context.clip();
  }

  clipPasses() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let clipPath = new Path2D();

    for (let i = 0; i < this.battlegroundPasses.length; i++) {
      clipPath.addPath(this.battlegroundPasses[i].path, matrix);
    }

    return clipPath;
  }

  clipTeamDeathmatches() {
    if (this.minimapCanvas.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

    context.beginPath();

    for (let i = 0; i < this.battlegroundTeamDeathmatches.length; i++) {
      context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].coordinates[0][0]),
                     this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].coordinates[0][1]));

      for (let j = 1; j < this.battlegroundTeamDeathmatches[i].coordinates.length; j++) {
        context.lineTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].coordinates[j][0]),
                       this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].coordinates[j][1]));
      }

      context.lineTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].coordinates[0][0]),
                     this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].coordinates[0][1]));
    }

    context.closePath();

    context.clip();
  }

  componentDidMount() {
    if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
      this.isTouchDevice = true;

      const lbze = document.getElementById('lbze');

      lbze.style.fontSize = "20px";
    }

    this.battlegrounds["Erangel"] = new Battleground(8127.75, 8127.75,
                                                     [0, 0],
                                                     "/minimaps/Erangel_Minimap_Cropped",
                                                     this.updateHighResolutionMinimapCanvas.bind(this),
                                                     this.drawCanvas.bind(this),
                                                     this.updateMinimapDimensions.bind(this),
                                                     [new PhaseDefaultValues(90, 240, 270, 0.4, 0.35, 0.5, 0),
                                                      new PhaseDefaultValues(0, 120, 90, 0.6, 0.55, 0.56, 0),
                                                      new PhaseDefaultValues(0, 100, 80, 0.8, 0.6, 0.56, 0),
                                                      new PhaseDefaultValues(0, 100, 80, 1, 0.55, 0.5, 0),
                                                      new PhaseDefaultValues(0, 100, 60, 3, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 90, 30, 5, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 70, 30, 7, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 60, 30, 9, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(30, 30, 30, 11, 0.001, 10, 0)],
                                                     [new SpecialNormalMode("Ranked/Esports",
                                                                            [new PhaseDefaultValues(90, 240, 270, 0.6, 0.35, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 90, 120, 0.8, 0.55, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 1, 0.6, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 3, 0.6, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 60, 120, 5, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 8, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 10, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 14, 0.7, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 10, 160, 18, 0.001, 10, 0)]),
                                                      new SpecialNormalMode("AI Training Match",
                                                                            [new PhaseDefaultValues(120, 270, 300, 0.4, 0.35, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 180, 120, 0.6, 0.6, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 130, 90, 0.8, 0.55, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 120, 60, 1, 0.55, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 100, 60, 2, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 90, 30, 3, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 70, 30, 4, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 30, 6, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(30, 30, 30, 11, 0.001, 0.5, 0)]),
                                                      new SpecialNormalMode("Ghillie Crossing",
                                                                            [new PhaseDefaultValues(0, 120, 180, 0.1, 0.4, 10, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 0.5, 0.7, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 70, 110, 1, 0.6, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 80, 100, 2, 0.6, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 90, 90, 4, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 80, 80, 8, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 10, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 16, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(30, 65, 120, 100, 0.001, 0.5, 0)]),
                                                      new SpecialNormalMode("Intense Battle Royale",
                                                                            [new PhaseDefaultValues(40, 60, 120, 3, 0.6, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 60, 90, 5, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 8, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 10, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 14, 0.7, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 10, 60, 18, 0.001, 10, 0)])]);
    this.battlegrounds["Miramar"] = new Battleground(8160, 8160,
                                                     [0, 0],
                                                     "/minimaps/Miramar_Minimap",
                                                     this.updateHighResolutionMinimapCanvas.bind(this),
                                                     this.drawCanvas.bind(this),
                                                     this.updateMinimapDimensions.bind(this),
                                                     [new PhaseDefaultValues(90, 240, 270, 0.4, 0.35, 0.5, 0),
                                                      new PhaseDefaultValues(0, 120, 90, 0.6, 0.55, 0.56, 0),
                                                      new PhaseDefaultValues(0, 100, 80, 0.8, 0.6, 0.56, 0),
                                                      new PhaseDefaultValues(0, 100, 80, 1, 0.55, 0.5, 0),
                                                      new PhaseDefaultValues(0, 100, 60, 3, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 90, 30, 5, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 70, 30, 7, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 60, 30, 9, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(30, 30, 30, 11, 0.001, 10, 0)],
                                                     [new SpecialNormalMode("Ranked/Esports",
                                                                            [new PhaseDefaultValues(90, 240, 270, 0.6, 0.35, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 90, 120, 0.8, 0.55, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 1, 0.6, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 3, 0.6, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 60, 120, 5, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 8, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 10, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 14, 0.7, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 10, 160, 18, 0.001, 10, 0)]),
                                                      new SpecialNormalMode("Intense Battle Royale",
                                                                            [new PhaseDefaultValues(40, 60, 120, 3, 0.6, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 60, 90, 5, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 8, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 10, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 14, 0.7, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 10, 60, 18, 0.001, 10, 0)])]);
    this.battlegrounds["Sanhok"] = new Battleground(4047.75, 4047.75,
                                                    [0, 0],
                                                    "/minimaps/Sanhok_Minimap_Cropped",
                                                    this.updateHighResolutionMinimapCanvas.bind(this),
                                                    this.drawCanvas.bind(this),
                                                    this.updateMinimapDimensions.bind(this),
                                                    [new PhaseDefaultValues(90, 120, 240, 0.4, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 120, 120, 0.6, 0.7, 0.45, 0),
                                                     new PhaseDefaultValues(0, 90, 120, 0.8, 0.6, 0.4, 0),
                                                     new PhaseDefaultValues(0, 60, 120, 1, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 45, 60, 3, 0.5, 0.55, 0),
                                                     new PhaseDefaultValues(0, 45, 60, 5, 0.5, 0.6, 0),
                                                     new PhaseDefaultValues(0, 45, 40, 7, 0.5, 0.6, 0),
                                                     new PhaseDefaultValues(0, 45, 40, 9, 0.5, 0.65, 0),
                                                     new PhaseDefaultValues(0, 30, 60, 16, 0.001, 100, 0)],
                                                    [new SpecialNormalMode("Intense Battle Royale",
                                                                            [new PhaseDefaultValues(40, 60, 120, 3, 0.6, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 60, 90, 5, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 8, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 10, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 14, 0.7, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 10, 60, 18, 0.001, 10, 0)])]);
    this.battlegrounds["Vikendi"] = new Battleground(8160, 8160,
                                                     [0, 0],
                                                     "/minimaps/Vikendi_Minimap",
                                                     this.updateHighResolutionMinimapCanvas.bind(this),
                                                     this.drawCanvas.bind(this),
                                                     this.updateMinimapDimensions.bind(this),
                                                     [new PhaseDefaultValues(90, 240, 270, 0.4, 0.35, 0.5, 0),
                                                      new PhaseDefaultValues(0, 120, 90, 0.6, 0.55, 0.56, 0),
                                                      new PhaseDefaultValues(0, 100, 80, 0.8, 0.6, 0.56, 0),
                                                      new PhaseDefaultValues(0, 100, 80, 1, 0.55, 0.5, 0),
                                                      new PhaseDefaultValues(0, 100, 60, 3, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 90, 30, 5, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 70, 30, 7, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 60, 30, 9, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(30, 30, 30, 11, 0.001, 10, 0)],
                                                     [new SpecialNormalMode("Ranked/Esports",
                                                                            [new PhaseDefaultValues(90, 240, 270, 0.6, 0.35, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 90, 120, 0.8, 0.55, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 1, 0.6, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 3, 0.6, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 60, 120, 5, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 120, 8, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 10, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 14, 0.7, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 10, 160, 18, 0.001, 10, 0)]),
                                                      new SpecialNormalMode("Intense Battle Royale",
                                                                            [new PhaseDefaultValues(40, 60, 120, 3, 0.6, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 60, 90, 5, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 8, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 10, 0.65, 0.56, 0),
                                                                             new PhaseDefaultValues(0, 45, 60, 14, 0.7, 0.56, 1),
                                                                             new PhaseDefaultValues(0, 10, 60, 18, 0.001, 10, 0)])]);
    this.battlegrounds["Karakin"] = new Battleground(2023.5, 2023.5,
                                                     [0, 0],
                                                     "/minimaps/Karakin_Minimap_Cropped",
                                                     this.updateHighResolutionMinimapCanvas.bind(this),
                                                     this.drawCanvas.bind(this),
                                                     this.updateMinimapDimensions.bind(this),
                                                     [new PhaseDefaultValues(60, 120, 120, 0.4, 0.55, 0.6, 0),
                                                      new PhaseDefaultValues(0, 120, 90, 0.8, 0.7, 0.5, 0),
                                                      new PhaseDefaultValues(0, 90, 90, 1, 0.6, 0.5, 0),
                                                      new PhaseDefaultValues(0, 60, 60, 2, 0.6, 0.5, 0),
                                                      new PhaseDefaultValues(0, 60, 60, 4, 0.6, 0.5, 0),
                                                      new PhaseDefaultValues(0, 30, 60, 6, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 30, 30, 8, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 30, 30, 10, 0.5, 0.5, 0),
                                                      new PhaseDefaultValues(0, 30, 30, 16, 0.001, 0.5, 0)],
                                                     [new SpecialNormalMode("Bizarre Battle Royale",
                                                                            [new PhaseDefaultValues(60, 120, 120, 0.9, 0.5, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 1.9, 0.6, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 90, 3, 0.6, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 7, 0.65, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 14, 0.65, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 60, 60, 25, 0.7, 0.5, 0),
                                                                             new PhaseDefaultValues(0, 10, 90, 40, 0.001, 0.5, 0),
                                                                             new PhaseDefaultValues("", "", "", "", "", "", ""),
                                                                             new PhaseDefaultValues("", "", "", "", "", "", "")])]);
    this.battlegrounds["Paramo"] = new Battleground(3060, 3060,
                                                    [0, 0],
                                                    "/minimaps/Paramo_Minimap_Random",
                                                    this.updateHighResolutionMinimapCanvas.bind(this),
                                                    this.drawCanvas.bind(this),
                                                    this.updateMinimapDimensions.bind(this),
                                                    [new PhaseDefaultValues(90, 150, 210, 0.4, 0.48, 0.6, 0),
                                                     new PhaseDefaultValues(0, 150, 120, 0.8, 0.65, 0.45, 0),
                                                     new PhaseDefaultValues(0, 90, 90, 1.2, 0.55, 0.5, 0),
                                                     new PhaseDefaultValues(0, 60, 90, 2, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 60, 45, 4, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 45, 45, 6, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 45, 30, 8, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 45, 30, 10, 0.5, 0.6, 0),
                                                     new PhaseDefaultValues(30, 30, 30, 14, 0.001, 100, 0)],
                                                    []);
    this.battlegrounds["Haven"] = new Battleground(1130.0, 1130.0,
                                                   [55, 55],
                                                   "/minimaps/Haven_Minimap",
                                                   this.updateHighResolutionMinimapCanvas.bind(this),
                                                   this.drawCanvas.bind(this),
                                                   this.updateMinimapDimensions.bind(this),
                                                   [new PhaseDefaultValues(60, 120, 90, 0.4, 0.5, 0.6, 0),
                                                    new PhaseDefaultValues(0, 120, 75, 0.8, 0.7, 0.5, 0),
                                                    new PhaseDefaultValues(0, 90, 60, 1, 0.6, 0.5, 0),
                                                    new PhaseDefaultValues(0, 60, 60, 2, 0.6, 0.5, 0),
                                                    new PhaseDefaultValues(0, 60, 45, 4, 0.6, 0.5, 0),
                                                    new PhaseDefaultValues(0, 30, 30, 6, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 30, 30, 8, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 30, 30, 10, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 30, 30, 16, 0.001, 0.5, 0)],
                                                   []);
    this.battlegrounds["Taego"] = new Battleground(8160, 8160,
                                                   [0, 0],
                                                   "/minimaps/Taego_Minimap",
                                                   this.updateHighResolutionMinimapCanvas.bind(this),
                                                   this.drawCanvas.bind(this),
                                                   this.updateMinimapDimensions.bind(this),
                                                   [new PhaseDefaultValues(90, 240, 270, 0.4, 0.35, 0.5, 0),
                                                    new PhaseDefaultValues(0, 120, 90, 0.6, 0.55, 0.56, 0),
                                                    new PhaseDefaultValues(0, 100, 80, 0.8, 0.6, 0.56, 0),
                                                    new PhaseDefaultValues(0, 100, 80, 1, 0.55, 0.5, 0),
                                                    new PhaseDefaultValues(0, 100, 60, 3, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 90, 30, 5, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 70, 30, 7, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 60, 30, 9, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(30, 30, 30, 11, 0.001, 10, 0)],
                                                   [new SpecialNormalMode("Ranked/Esports",
                                                                          [new PhaseDefaultValues(90, 240, 270, 0.6, 0.35, 0.5, 0),
                                                                           new PhaseDefaultValues(0, 90, 120, 0.8, 0.55, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 120, 1, 0.6, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 120, 3, 0.6, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 60, 120, 5, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 120, 8, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 90, 10, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 60, 14, 0.7, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 10, 160, 18, 0.001, 10, 0)]),
                                                    new SpecialNormalMode("Intense Battle Royale",
                                                                          [new PhaseDefaultValues(40, 60, 120, 3, 0.6, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 60, 90, 5, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 90, 8, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 45, 60, 10, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 45, 60, 14, 0.7, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 10, 60, 18, 0.001, 10, 0)])]);
    this.battlegrounds["CampJackal"] = new Battleground(2023.5, 2023.5,
                                                        [0, 0],
                                                        "/minimaps/Camp_Jackal_Minimap_Cropped",
                                                        this.updateHighResolutionMinimapCanvas.bind(this),
                                                        this.drawCanvas.bind(this),
                                                        this.updateMinimapDimensions.bind(this),
                                                        null,
                                                        []);
    this.battlegrounds["Deston"] = new Battleground(8160, 8160,
                                                    [0, 0],
                                                    "/minimaps/Deston_Minimap",
                                                    this.updateHighResolutionMinimapCanvas.bind(this),
                                                    this.drawCanvas.bind(this),
                                                    this.updateMinimapDimensions.bind(this),
                                                    [new PhaseDefaultValues(90, 240, 270, 0.4, 0.35, 0.5, 0),
                                                     new PhaseDefaultValues(0, 120, 90, 0.6, 0.55, 0.56, 0),
                                                     new PhaseDefaultValues(0, 100, 80, 0.8, 0.6, 0.56, 0),
                                                     new PhaseDefaultValues(0, 100, 80, 1, 0.55, 0.5, 0),
                                                     new PhaseDefaultValues(0, 100, 60, 3, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 90, 30, 5, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 70, 30, 7, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(0, 60, 30, 9, 0.5, 0.5, 0),
                                                     new PhaseDefaultValues(30, 30, 30, 11, 0.001, 10, 0)],
                                                    [new SpecialNormalMode("Ranked/Esports",
                                                                           [new PhaseDefaultValues(90, 240, 270, 0.6, 0.35, 0.5, 0),
                                                                            new PhaseDefaultValues(0, 90, 120, 0.8, 0.55, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 60, 120, 1, 0.6, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 60, 120, 3, 0.6, 0.56, 1),
                                                                            new PhaseDefaultValues(0, 60, 120, 5, 0.65, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 60, 120, 8, 0.65, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 60, 90, 10, 0.65, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 60, 60, 14, 0.7, 0.56, 1),
                                                                            new PhaseDefaultValues(0, 10, 160, 18, 0.001, 10, 0)]),
                                                     new SpecialNormalMode("Intense Battle Royale",
                                                                           [new PhaseDefaultValues(40, 60, 120, 3, 0.6, 0.56, 1),
                                                                            new PhaseDefaultValues(0, 60, 90, 5, 0.65, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 60, 90, 8, 0.65, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 45, 60, 10, 0.65, 0.56, 0),
                                                                            new PhaseDefaultValues(0, 45, 60, 14, 0.7, 0.56, 1),
                                                                            new PhaseDefaultValues(0, 10, 60, 18, 0.001, 10, 0)])]);
    this.battlegrounds["Boardwalk"] = new Battleground(253.9625, 253.9625,
                                                       [-942.08125, -895.85125],
                                                       "/minimaps/Boardwalk_Minimap",
                                                       this.updateHighResolutionMinimapCanvas.bind(this),
                                                       this.drawCanvas.bind(this),
                                                       this.updateMinimapDimensions.bind(this),
                                                       null,
                                                       []);
    this.battlegrounds["PillarCompound"] = new Battleground(250.0, 250.0,
                                                            [-892.0, -894.0],
                                                            "/minimaps/Pillar_Compound_Minimap",
                                                            this.updateHighResolutionMinimapCanvas.bind(this),
                                                            this.drawCanvas.bind(this),
                                                            this.updateMinimapDimensions.bind(this),
                                                            null,
                                                            []);
    this.battlegrounds["Rondo"] = new Battleground(8160, 8160,
                                                   [0, 0],
                                                   "/minimaps/Rondo_Minimap",
                                                   this.updateHighResolutionMinimapCanvas.bind(this),
                                                   this.drawCanvas.bind(this),
                                                   this.updateMinimapDimensions.bind(this),
                                                   [new PhaseDefaultValues(90, 240, 270, 0.4, 0.35, 0.5, 0),
                                                    new PhaseDefaultValues(0, 120, 90, 0.6, 0.55, 0.56, 0),
                                                    new PhaseDefaultValues(0, 100, 80, 0.8, 0.6, 0.56, 0),
                                                    new PhaseDefaultValues(0, 100, 80, 1, 0.55, 0.5, 0),
                                                    new PhaseDefaultValues(0, 100, 60, 3, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 90, 30, 5, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 70, 30, 7, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(0, 60, 30, 9, 0.5, 0.5, 0),
                                                    new PhaseDefaultValues(30, 30, 30, 11, 0.001, 10, 0)],
                                                   [new SpecialNormalMode("Ranked/Esports",
                                                                          [new PhaseDefaultValues(90, 240, 270, 0.6, 0.35, 0.5, 0),
                                                                           new PhaseDefaultValues(0, 90, 120, 0.8, 0.55, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 120, 1, 0.6, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 120, 3, 0.6, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 60, 120, 5, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 120, 8, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 90, 10, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 60, 14, 0.7, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 10, 160, 18, 0.001, 10, 0)]),
                                                    new SpecialNormalMode("Intense Battle Royale",
                                                                          [new PhaseDefaultValues(40, 60, 120, 3, 0.6, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 60, 90, 5, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 60, 90, 8, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 45, 60, 10, 0.65, 0.56, 0),
                                                                           new PhaseDefaultValues(0, 45, 60, 14, 0.7, 0.56, 1),
                                                                           new PhaseDefaultValues(0, 10, 60, 18, 0.001, 10, 0)])]);
    this.battlegrounds["Liana"] = new Battleground(220.0, 220.0,
                                                   [-910.0, -945.0],
                                                   "/minimaps/Liana_Minimap",
                                                   this.updateHighResolutionMinimapCanvas.bind(this),
                                                   this.drawCanvas.bind(this),
                                                   this.updateMinimapDimensions.bind(this),
                                                   null,
                                                   []);

    const search = window.location.search;
    const searchParameters = new URLSearchParams(search);

    if (this.battleground.current != null) {
      let searchParameter = searchParameters.get("m");

      if (searchParameter != null) {
        if ((searchParameter === "Erangel") ||
            (searchParameter === "Miramar") ||
            (searchParameter === "Sanhok") ||
            (searchParameter === "Vikendi") ||
            (searchParameter === "Karakin") ||
            (searchParameter === "Paramo") ||
            (searchParameter === "Haven") ||
            (searchParameter === "Taego") ||
            (searchParameter === "Deston") ||
            (searchParameter === "Haven") ||
            (searchParameter === "CampJackal") ||
            (searchParameter === "Boardwalk") ||
            (searchParameter === "PillarCompound") ||
            (searchParameter === "Rondo") ||
            (searchParameter === "Liana")) {
          this.battleground.current.value = searchParameter;
        } else if ((searchParameter === "FantasyBR") ||
                   (searchParameter === "HalloweenBR")) {
          this.battleground.current.value = "Erangel";
        } else if (/Miramar_[0-9.]+/.test(searchParameter)) {
          this.battleground.current.value = "Miramar";
        } else if (/Sanhok_[0-9.]+/.test(searchParameter)) {
          this.battleground.current.value = "Sanhok";
        } else if (/Vikendi_[0-9.]+/.test(searchParameter)) {
          this.battleground.current.value = "Vikendi";
        }
      }

      this.maximumMinimapScale = this.battlegroundMaximumMinimapScale(this.battleground.current.value);
    }

    let minimapScale = this.minimapScale;

    { let searchParameter = searchParameters.get("ms");

      if (searchParameter != null) {
        if (!isNaN(searchParameter)) {
          minimapScale = Number(searchParameter);
        }
    } }

    let minimapOffsetX = this.minimapOffsetX;

    { let searchParameter = searchParameters.get("mx");

      if (searchParameter != null) {
        if (!isNaN(searchParameter)) {
          minimapOffsetX = -Number(searchParameter) * minimapScale * this.minimapCanvas.current.width + 0.5 * this.minimapCanvas.current.width;
        }
    } }

    let minimapOffsetY = this.minimapOffsetY;

    { let searchParameter = searchParameters.get("my");

      if (searchParameter != null) {
        if (!isNaN(searchParameter)) {
          minimapOffsetY = -Number(searchParameter) * minimapScale * this.minimapCanvas.current.height + 0.5 * this.minimapCanvas.current.height;
        }
    } }

    this.setMinimapViewport(minimapScale, minimapOffsetX, minimapOffsetY);

    this.selectBattleground();

    if (this.mode.current != null) {
      let searchParameter = searchParameters.get("mo");

      if (searchParameter != null) {
        if ((searchParameter === "-") ||
            (searchParameter === "Normal") ||
            (searchParameter === "TDM") ||
            (searchParameter === "IntenseBR")) {
          this.mode.current.value = searchParameter;
        }
      }
    }

    if (this.showErrorSpaces.current != null) {
      let searchParameter = searchParameters.get("es");

      if (searchParameter != null) {
        this.showErrorSpaces.current.checked = searchParameter === "true";
      }
    }

    if (this.shape.current != null) {
      let searchParameter = searchParameters.get("s");

      if (searchParameter != null) {
        if ((searchParameter === "Circle") ||
            (searchParameter === "Square")) {
          this.shape.current.value = searchParameter;
        }
      }
    }

    if (this.circleCenterCoordinate.current != null) {
      if (this.circleCenterCoordinate.current.x.current != null) {
        let searchParameter = searchParameters.get("cx");

        if (searchParameter != null) {
          this.circleCenterCoordinate.current.x.current.value = searchParameter;
        }
      }

      if (this.circleCenterCoordinate.current.y.current != null) {
        let searchParameter = searchParameters.get("cy");

        if (searchParameter != null) {
          this.circleCenterCoordinate.current.y.current.value = searchParameter;
        }
      }
    }

    if (this.squareCoordinates.current != null) {
      for (let i = 0; i < 2; i++) {
        if (this.squareCoordinates.current.x[i].current != null) {
          let searchParameter = searchParameters.get("sx" + (i + 1));

          if (searchParameter != null) {
            this.squareCoordinates.current.x[i].current.value = searchParameter;
          }
        }

        if (this.squareCoordinates.current.y[i].current != null) {
          let searchParameter = searchParameters.get("sy" + (i + 1));

          if (searchParameter != null) {
            this.squareCoordinates.current.y[i].current.value = searchParameter;
          }
        }
      }
    }

    this.selectShape();

    this.setPhaseValuesForAllPhases();

    for (let i = 0; i < 9; i++) {
      if (this.phases[i].current != null) {
        const phaseName = "p" + (i + 1);

        if (this.phases[i].current.delay.current != null) {
          let searchParameter = searchParameters.get(phaseName + "de");

          if (searchParameter != null) {
            this.phases[i].current.delay.current.value = searchParameter;
          }
        }

        if (this.phases[i].current.wait.current != null) {
          let searchParameter = searchParameters.get(phaseName + "w");

          if (searchParameter != null) {
            this.phases[i].current.wait.current.value = searchParameter;
          }
        }

        if (this.phases[i].current.move.current != null) {
          let searchParameter = searchParameters.get(phaseName + "m");

          if (searchParameter != null) {
            this.phases[i].current.move.current.value = searchParameter;
          }
        }

        if (this.phases[i].current.dps.current != null) {
          let searchParameter = searchParameters.get(phaseName + "da");

          if (searchParameter != null) {
            this.phases[i].current.dps.current.value = searchParameter;
          }
        }

        if (this.phases[i].current.shrink.current != null) {
          let searchParameter = searchParameters.get(phaseName + "sh");

          if (searchParameter != null) {
            this.phases[i].current.shrink.current.value = searchParameter;
          }
        }

        if (this.phases[i].current.shrink.current != null) {
          let searchParameter = searchParameters.get(phaseName + "sh");

          if (searchParameter != null) {
            this.phases[i].current.shrink.current.value = searchParameter;
          }
        }

        if (this.phases[i].current.spread.current != null) {
          let searchParameter = searchParameters.get(phaseName + "sp");

          if (searchParameter != null) {
            this.phases[i].current.spread.current.value = searchParameter;
          }
        }

        if (this.phases[i].current.landRatio.current != null) {
          let searchParameter = searchParameters.get(phaseName + "lr");

          if (searchParameter != null) {
            this.phases[i].current.landRatio.current.value = searchParameter;
          }
        }
      }
    }

    if (this.showPasses.current != null) {
      let searchParameter = searchParameters.get("pe");

      if (searchParameter != null) {
        this.showPasses.current.checked = searchParameter === "true";
      }
    }

    if (this.showFixedEndFieldCircles.current != null) {
      let searchParameter = searchParameters.get("fef");

      if (searchParameter != null) {
        this.showFixedEndFieldCircles.current.checked = searchParameter === "true";
      }
    }

    if (this.showFixedEndMountainCircles.current != null) {
      let searchParameter = searchParameters.get("fem");

      if (searchParameter != null) {
        this.showFixedEndMountainCircles.current.checked = searchParameter === "true";
      }
    }

    if (this.showFixedEndTownCircles.current != null) {
      let searchParameter = searchParameters.get("fet");

      if (searchParameter != null) {
        this.showFixedEndTownCircles.current.checked = searchParameter === "true";
      }
    }

    this.updateAnnotations();

    this.fetchParamoRandomData();

    this.fetchErrorSpacesData();

    this.fetchFixedEndCirclesData();

    this.fetchPassData();

    this.fetchIntenseBattleRoyaleData();

    this.fetchTeamDeathmatchData();

    this.updateMinimapDimensions();

    window.addEventListener('resize', this.handleResize);

    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);

    this.minimapCanvas.current.addEventListener('wheel', this.scaleMinimap, { passive: false });

    this.mounted = true;
  }

  convertMapToCanvasCoordinateX(x) {
    return (x * this.minimapScale * this.minimapCanvas.current.width + this.minimapOffsetX);
  }

  convertMapToCanvasCoordinateY(y) {
    return (y * this.minimapScale * this.minimapCanvas.current.height + this.minimapOffsetY);
  }

  convertSecondsToMinutesAndSecondsString(seconds) {
    let stringSeconds = seconds;

    let stringMinutes = Math.floor(stringSeconds / 60);

    stringSeconds -= 60 * stringMinutes;

    if (stringMinutes === 0) {
      return stringSeconds + "s";
    } else {
      return stringMinutes + "m" + stringSeconds + "s";
    }
  }

  convertSecondsToPhaseString(seconds) {
    let phaseSeconds = Number(seconds);

    let phaseNumber = 9;
    let phaseDuration;
    for (let i = 0; i < 8; i++) {
      if (this.phases[i].current == null) {
        return "";
      }

      phaseDuration = Number(this.phases[i].current.delay.current.value) +
                      Number(this.phases[i].current.wait.current.value) +
                      Number(this.phases[i].current.move.current.value);

      if (phaseDuration > phaseSeconds) {
        phaseNumber = i + 1;

        break;
      }

      phaseSeconds -= phaseDuration;
    }

    let phaseString = "Phase " + phaseNumber + "\n+" + this.convertSecondsToMinutesAndSecondsString(phaseSeconds);

    let phaseName;

    if (phaseNumber < 9) {
      phaseName = "Phase " + (phaseNumber + 1);
    } else {
      phaseName = "Round End";
    }

    const i = phaseNumber - 1;

    phaseDuration = Number(this.phases[i].current.delay.current.value) +
                    Number(this.phases[i].current.wait.current.value) +
                    Number(this.phases[i].current.move.current.value);

    phaseSeconds = Math.abs(phaseSeconds - phaseDuration);

    phaseString += "\n—\n" + phaseName + "\n-" + this.convertSecondsToMinutesAndSecondsString(phaseSeconds);

    return phaseString;
  }

  convertShrinkToDiameter(shrink) {
    if (this.battleground.current != null) {
      let meters = 2.0 * shrink * this.battlegrounds[this.battleground.current.value].radius;
      meters = meters.toPrecision(3);

      if (meters > 1000.0) {
        meters = meters / 1000.0 + " km";
      } else {
        meters = meters + " m";
      }

      return meters;
    } else {
      return "";
    }
  }

  convertShrinkToRadius(shrink) {
    if (this.battleground.current != null) {
      let meters = shrink * this.battlegrounds[this.battleground.current.value].radius;
      meters = meters.toPrecision(3);
    
      if (meters > 1000.0) {
        meters = meters / 1000.0 + " km";
      } else {
        meters = meters + " m";
      }
    
      return meters;
    } else {
      return "";
    }
  } 

  convertWeightToPercentChance(weight, totalWeight) {
    let percent = weight / totalWeight * 100;

    return (percent.toPrecision(3) / 1) + "%"; // divide by 1 to remove trailing zeroes
  }

  drawCanvas(renderHTML) {
    if (this.battleground.current == null) { return; }

    if (this.minimapCanvas.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

    if (this.battlegrounds[this.battleground.current.value].minimap.loadedImages()) {
      context.imageSmoothingEnabled = false;

      const mode = this.getMode();

      context.clearRect(0, 0, this.minimapCanvas.current.width, this.minimapCanvas.current.height);

      if ((mode === "TDM") || (mode === "IntenseBR")) { context.filter = "grayscale(1)"; }

      this.drawMinimap();

      if ((mode === "-") || (mode === "Normal")) {
        let drawFixedEndFieldCircles = false;

        if (this.showFixedEndFieldCircles.current != null) {
          if (this.showFixedEndFieldCircles.current.checked && (this.battlegroundFixedEndFieldCircles.length > 0)) {
            drawFixedEndFieldCircles = true;

            this.drawFixedEndFieldCirclesStrokes();
          }
        }

        let drawFixedEndMountainCircles = false;

        if (this.showFixedEndMountainCircles.current != null) {
          if (this.showFixedEndMountainCircles.current.checked && (this.battlegroundFixedEndMountainCircles.length > 0)) {
            drawFixedEndMountainCircles = true;

            this.drawFixedEndMountainCirclesStrokes();
          }
        }

        let drawFixedEndTownCircles = false;

        if (this.showFixedEndTownCircles.current != null) {
          if (this.showFixedEndTownCircles.current.checked && (this.battlegroundFixedEndTownCircles.length > 0)) {
            drawFixedEndTownCircles = true;

            this.drawFixedEndTownCirclesStrokes();
          }
        }

        if (drawFixedEndFieldCircles || drawFixedEndMountainCircles || drawFixedEndTownCircles) {
          context.save();

          let clipPath = new Path2D();

          if (drawFixedEndFieldCircles) { clipPath.addPath(this.clipFixedEndFieldCircles()); }

          if (drawFixedEndMountainCircles) { clipPath.addPath(this.clipFixedEndMountainCircles()); }

          if (drawFixedEndTownCircles) { clipPath.addPath(this.clipFixedEndTownCircles()); }

          context.clip(clipPath);

          this.drawMinimap();

          if (drawFixedEndFieldCircles) { this.drawFixedEndFieldCirclesFills(); }

          if (drawFixedEndMountainCircles) { this.drawFixedEndMountainCirclesFills(); }

          if (drawFixedEndTownCircles) { this.drawFixedEndTownCirclesFills(); }

          context.restore();
        }

        if (this.showPasses.current != null) {
          if (this.showPasses.current.checked && (this.battlegroundPasses.length > 0)) {
            context.save();

            this.drawPassesStrokes();

            context.clip(this.clipPasses());

            this.drawMinimap();

            this.drawPassesFills();

            context.restore();
          }
        }

        if (this.showErrorSpaces.current != null) {
          if (this.showErrorSpaces.current.checked && (this.battlegroundErrorSpaces.length > 0)) {
            this.drawErrorSpaces();
          }
        }

        if (mode === "Normal") {
          const shape = this.getShape();

          if (shape === "Square") {
            this.drawSquares();
          } else if (shape === "Circle") {
            this.drawCircles();
          }
        }
      } else if ((mode === "TDM") || (mode === "IntenseBR")) {
        context.filter = "none";

        context.fillStyle = 'rgba(31, 94, 221, 0.5)';

        context.fillRect(0, 0, this.minimapCanvas.current.width, this.minimapCanvas.current.height);

        context.save();

        if ((mode === "TDM") && (this.battlegroundTeamDeathmatches.length > 0)) {
          this.clipTeamDeathmatches();

          this.drawMinimap();

          context.restore();

          this.drawTeamDeathmatches();
        } else if ((mode === "IntenseBR") && (this.battlegroundIntenseBattleRoyales.length > 0)) {
          this.clipIntenseBattleRoyales();

          this.drawMinimap();

          context.restore();

          this.drawIntenseBattleRoyales();
        }
      }
    } else {
      context.fillStyle = '#535355';
      context.fillRect (0, 0, this.minimapCanvas.current.width, this.minimapCanvas.current.height);
    }

    if (renderHTML) { this.setState({}); }
  }

  drawCircles() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    if (this.circleCenterCoordinate.current == null) { return; }
    if ((this.circleCenterCoordinate.current.x.current == null) || (this.circleCenterCoordinate.current.y.current == null)) { return; }

    for (let i = 0; i < 9; i++) {
      if (this.phases[i].current == null) { return; }
      if (this.phases[i].current.shrink.current == null) { return; }
    }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

    context.strokeStyle = 'rgba(255, 255, 255, 0.8)';
    context.lineWidth = 2.0;

    let radius = this.minimapScale * this.battlegrounds[this.battleground.current.value].normalizedRadius * this.minimapCanvas.current.width;

    let centerX = this.convertMapToCanvasCoordinateX(Number(this.circleCenterCoordinate.current.x.current.value));

    let centerY = this.convertMapToCanvasCoordinateY(Number(this.circleCenterCoordinate.current.y.current.value));

    context.beginPath();

    for (let i = 0; i < 9; i++) {
      radius *= Number(this.phases[i].current.shrink.current.value);

      context.moveTo(centerX + radius, centerY);

      context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    }

    context.stroke();

    context.closePath();
  }

  drawErrorSpaces() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    context.strokeStyle = 'rgba(250, 255, 84, 0.4)';
    context.lineWidth = 2.0;

    context.beginPath();

    for (let i = 0; i < this.battlegroundErrorSpaces.length; i++) {
      context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundErrorSpaces[i].coordinate[0] + this.battlegroundErrorSpaces[i].radius),
                     this.convertMapToCanvasCoordinateY(this.battlegroundErrorSpaces[i].coordinate[1]));

      context.arc(this.convertMapToCanvasCoordinateX(this.battlegroundErrorSpaces[i].coordinate[0]),
                  this.convertMapToCanvasCoordinateY(this.battlegroundErrorSpaces[i].coordinate[1]),
                  this.minimapScale * this.battlegroundErrorSpaces[i].radius * this.minimapCanvas.current.width,
                  0, 2 * Math.PI);
    }

    context.closePath();

    context.stroke();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    for (let i = 0; i < this.battlegroundErrorSpaces.length; i++) {
      let gradient = context.createRadialGradient(this.convertMapToCanvasCoordinateX(this.battlegroundErrorSpaces[i].coordinate[0]),
                                                  this.convertMapToCanvasCoordinateY(this.battlegroundErrorSpaces[i].coordinate[1]),
                                                  0.0,
                                                  this.convertMapToCanvasCoordinateX(this.battlegroundErrorSpaces[i].coordinate[0]),
                                                  this.convertMapToCanvasCoordinateY(this.battlegroundErrorSpaces[i].coordinate[1]),
                                                  this.minimapScale * this.battlegroundErrorSpaces[i].radius * this.minimapCanvas.current.width);
      gradient.addColorStop(0, 'rgba(123, 182, 217, 0.4)');
      gradient.addColorStop(1, 'rgba(56, 187, 139, 0.1)');

      context.fillStyle = gradient;

      context.beginPath();

      context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundErrorSpaces[i].coordinate[0] + this.battlegroundErrorSpaces[i].radius),
                     this.convertMapToCanvasCoordinateY(this.battlegroundErrorSpaces[i].coordinate[1]));

      context.arc(this.convertMapToCanvasCoordinateX(this.battlegroundErrorSpaces[i].coordinate[0]),
                  this.convertMapToCanvasCoordinateY(this.battlegroundErrorSpaces[i].coordinate[1]),
                  this.minimapScale * this.battlegroundErrorSpaces[i].radius * this.minimapCanvas.current.width,
                  0, 2 * Math.PI);

      context.closePath();

      context.fill();
    }
  }

  drawFixedEndFieldCirclesFills() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let fixedEndFieldCirclesPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndFieldCircles.length; i++) {
      fixedEndFieldCirclesPath.addPath(this.battlegroundFixedEndFieldCircles[i].path, matrix);
    }

    context.fillStyle = 'rgba(205, 164, 0, 0.3)';

    context.fill(fixedEndFieldCirclesPath);
  }

  drawFixedEndFieldCirclesStrokes() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let fixedEndFieldCirclesPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndFieldCircles.length; i++) {
      fixedEndFieldCirclesPath.addPath(this.battlegroundFixedEndFieldCircles[i].path, matrix);
    }

    context.strokeStyle = 'rgba(229, 184, 0, 0.8)';
    context.lineWidth = 4.0;

    context.stroke(fixedEndFieldCirclesPath);
  }

  drawFixedEndMountainCirclesFills() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let fixedEndMountainCirclesPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndMountainCircles.length; i++) {
      fixedEndMountainCirclesPath.addPath(this.battlegroundFixedEndMountainCircles[i].path, matrix);
    }

    context.fillStyle = 'rgba(152, 84, 0, 0.3)';

    context.fill(fixedEndMountainCirclesPath);
  }

  drawFixedEndMountainCirclesStrokes() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let fixedEndMountainCirclesPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndMountainCircles.length; i++) {
      fixedEndMountainCirclesPath.addPath(this.battlegroundFixedEndMountainCircles[i].path, matrix);
    }

    context.strokeStyle = 'rgba(179, 98, 0, 0.8)';
    context.lineWidth = 4.0;

    context.stroke(fixedEndMountainCirclesPath);
  }

  drawFixedEndTownCirclesFills() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let fixedEndTownCirclesPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndTownCircles.length; i++) {
      fixedEndTownCirclesPath.addPath(this.battlegroundFixedEndTownCircles[i].path, matrix);
    }

    context.fillStyle = 'rgba(179, 37, 25, 0.3)';

    context.fill(fixedEndTownCirclesPath);
  }

  drawFixedEndTownCirclesStrokes() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let fixedEndTownCirclesPath = new Path2D();

    for (let i = 0; i < this.battlegroundFixedEndTownCircles.length; i++) {
      fixedEndTownCirclesPath.addPath(this.battlegroundFixedEndTownCircles[i].path, matrix);
    }

    context.strokeStyle = 'rgba(203, 42, 28, 0.8)';
    context.lineWidth = 4.0;

    context.stroke(fixedEndTownCirclesPath);
  }

  drawIntenseBattleRoyales() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

    const battleground = this.battlegrounds[this.battleground.current.value];

    let specialNormalMode;

    for (let i = 0; i < battleground.specialNormalModes.length; i++) {
      if (battleground.specialNormalModes[i].name === "Intense Battle Royale") {
        specialNormalMode = battleground.specialNormalModes[i];

        break;
      }
    }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    context.strokeStyle = 'rgba(255, 255, 255, 0.8)';
    context.lineWidth = 2.0;

    context.beginPath();

    for (let i = 0; i < this.battlegroundIntenseBattleRoyales.length; i++) {
      let radius = this.battlegroundIntenseBattleRoyales[i].normalizedRadius;

      context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundIntenseBattleRoyales[i].coordinate[0] + radius),
                     this.convertMapToCanvasCoordinateY(this.battlegroundIntenseBattleRoyales[i].coordinate[1]));

      context.arc(this.convertMapToCanvasCoordinateX(this.battlegroundIntenseBattleRoyales[i].coordinate[0]),
                  this.convertMapToCanvasCoordinateY(this.battlegroundIntenseBattleRoyales[i].coordinate[1]),
                  this.minimapScale * radius * this.minimapCanvas.current.width,
                  0, 2 * Math.PI);
      for (let j = 0; j < specialNormalMode.phasesDefaultValues.length; j++) {
        radius *= specialNormalMode.phasesDefaultValues[j].shrink;

        context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundIntenseBattleRoyales[i].coordinate[0] + radius),
                       this.convertMapToCanvasCoordinateY(this.battlegroundIntenseBattleRoyales[i].coordinate[1]));

        context.arc(this.convertMapToCanvasCoordinateX(this.battlegroundIntenseBattleRoyales[i].coordinate[0]),
                    this.convertMapToCanvasCoordinateY(this.battlegroundIntenseBattleRoyales[i].coordinate[1]),
                    this.minimapScale * radius * this.minimapCanvas.current.width,
                    0, 2 * Math.PI);
      }
    }

    context.closePath();

    context.stroke();
  }

  drawMinimap() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const minimap = this.battlegrounds[this.battleground.current.value].minimap;

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

    if ((!(minimap.allHighResolutionImagesLoaded && (this.highResolutionMinimapCanvas.current != null)) || this.userAgentIsSafari) && minimap.lowResolutionImage.loaded) {
      context.drawImage(minimap.lowResolutionImage.image, this.minimapOffsetX, this.minimapOffsetY, this.minimapScale * this.minimapCanvas.current.width, this.minimapScale * this.minimapCanvas.current.height);
    }

    if (minimap.anyHighResolutionImageLoaded && (this.highResolutionMinimapCanvas.current != null)) {
      if (this.userAgentIsSafari) {
        const highResolutionWidthScale = this.minimapScale * this.minimapCanvas.current.width / minimap.highResolutionDimensions[0];
        const highResolutionHeightScale = this.minimapScale * this.minimapCanvas.current.height / minimap.highResolutionDimensions[1];

        const highResolutionImageWidth = Math.floor(minimap.divisionFractions[0] * minimap.highResolutionDimensions[0]);
        const highResolutionImageHeight = Math.floor(minimap.divisionFractions[1] * minimap.highResolutionDimensions[1]);

        for (let i = 0; i < minimap.highResolutionImages.length; i++) {
          if (minimap.highResolutionImages[i].loaded) {
            const highResolutionImageOffsetX = this.minimapOffsetX + highResolutionWidthScale * minimap.highResolutionImages[i].indexes[0] * highResolutionImageWidth;
            const highResolutionImageOffsetY = this.minimapOffsetY + highResolutionHeightScale * minimap.highResolutionImages[i].indexes[1] * highResolutionImageHeight;

            context.drawImage(minimap.highResolutionImages[i].image, highResolutionImageOffsetX, highResolutionImageOffsetY, highResolutionWidthScale * highResolutionImageWidth, highResolutionHeightScale * highResolutionImageHeight);
          }
        }

        if (minimap.allRandomElementsLoaded) {
          for (let i = 0; i < minimap.randomElements.length; i++) {
            if (minimap.randomElements[i].label.loaded) {
              const terrainOffset = [this.minimapOffsetX + highResolutionWidthScale * Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[0] + minimap.randomElements[i].terrain.offset[0]),
                                     this.minimapOffsetY + highResolutionHeightScale * Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[1] + minimap.randomElements[i].terrain.offset[1])];

              context.drawImage(minimap.randomElements[i].terrain.image, terrainOffset[0], terrainOffset[1], highResolutionWidthScale * minimap.randomElements[i].terrain.image.naturalWidth, highResolutionHeightScale * minimap.randomElements[i].terrain.image.naturalHeight);

              const objectOffset = [this.minimapOffsetX + highResolutionWidthScale * Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[0] + minimap.randomElements[i].object.offset[0]),
                                    this.minimapOffsetY + highResolutionHeightScale * Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[1] + minimap.randomElements[i].object.offset[1])];

              context.drawImage(minimap.randomElements[i].object.image, objectOffset[0], objectOffset[1], highResolutionWidthScale * minimap.randomElements[i].object.image.naturalWidth, highResolutionHeightScale * minimap.randomElements[i].object.image.naturalHeight);

              const labelOffset = [this.minimapOffsetX + highResolutionWidthScale * Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[0] + minimap.randomElements[i].label.offset[0]),
                                   this.minimapOffsetY + highResolutionHeightScale * Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[1] + minimap.randomElements[i].label.offset[1])];

              context.drawImage(minimap.randomElements[i].label.image, labelOffset[0], labelOffset[1], highResolutionWidthScale * minimap.randomElements[i].label.image.naturalWidth, highResolutionHeightScale * minimap.randomElements[i].label.image.naturalHeight);
            }
          }
        }
      } else {
        context.drawImage(this.highResolutionMinimapCanvas.current, this.minimapOffsetX, this.minimapOffsetY, this.minimapScale * this.minimapCanvas.current.width, this.minimapScale * this.minimapCanvas.current.height);
      }
    }
  }

  drawPassesFills() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let passesPath = new Path2D();

    for (let i = 0; i < this.battlegroundPasses.length; i++) {
      passesPath.addPath(this.battlegroundPasses[i].path, matrix);
    }

    context.fillStyle = 'rgba(101, 0, 179, 0.3)';

    context.fill(passesPath);
  }

  drawPassesStrokes() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const scaleX = this.minimapScale * this.minimapCanvas.current.width;
    const scaleY = this.minimapScale * this.minimapCanvas.current.height;

    const matrix = new DOMMatrix([scaleX, 0.0, 0.0, scaleY,
                                  this.minimapOffsetX, this.minimapOffsetY]);

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let passesPath = new Path2D();

    for (let i = 0; i < this.battlegroundPasses.length; i++) {
      passesPath.addPath(this.battlegroundPasses[i].path, matrix);
    }

    context.strokeStyle = 'rgba(116, 0, 205, 0.8)';
    context.lineWidth = 4.0;

    context.stroke(passesPath);
  }

  drawSquares() {
    if (this.minimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    if (this.squareCoordinates.current == null) { return; }
    if ((this.squareCoordinates.current.x[0].current == null) || (this.squareCoordinates.current.x[1].current == null) ||
        (this.squareCoordinates.current.y[0].current == null) || (this.squareCoordinates.current.y[1].current == null)) {
      return;
    }

    for (let i = 0; i < 9; i++) {
      if (this.phases[i].current == null) { return; }
      if (this.phases[i].current.shrink.current == null) { return; }
    }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

    context.fillStyle = 'rgba(255, 255, 255, 0.8)';
    context.strokeStyle = 'rgba(255, 255, 255, 0.8)';
    context.lineWidth = 2.0;

    if (this.mouseDown) {
      if (this.mouseDownShiftKey) {
        let cornerX = [this.convertMapToCanvasCoordinateX(this.shiftClickCoordinateX),
                       this.convertMapToCanvasCoordinateX(this.shiftDragCoordinateX)];
        let cornerY = [this.convertMapToCanvasCoordinateY(this.shiftClickCoordinateY),
                       this.convertMapToCanvasCoordinateY(this.shiftDragCoordinateY)];

        let centerX = 0.5 * (cornerX[0] + cornerX[1]);
        let width = cornerX[1] - cornerX[0];

        let centerY = 0.5 * (cornerY[0] + cornerY[1]);
        let height = cornerY[1] - cornerY[0];

        if ((width !== 0) || (height !== 0)) {
          context.strokeRect(centerX - 0.5 * width, centerY - 0.5 * height, width, height);
        } else {
          context.fillRect(centerX - 2.0, centerY - 2.0, 4.0, 4.0);
        }

        return;
      } else if (this.mouseDownCtrlAltKey) {
        let centerX = this.convertMapToCanvasCoordinateX(this.ctrlAltClickCoordinateX);
        let centerY = this.convertMapToCanvasCoordinateY(this.ctrlAltClickCoordinateY);
    
        let width = 2.0 * Math.abs(this.convertMapToCanvasCoordinateX(this.ctrlAltClickCoordinateX) -
                                   this.convertMapToCanvasCoordinateX(this.ctrlAltDragCoordinateX));
        let height = 2.0 * Math.abs(this.convertMapToCanvasCoordinateY(this.ctrlAltClickCoordinateY) -
                                    this.convertMapToCanvasCoordinateY(this.ctrlAltDragCoordinateY));

        if ((width !== 0) || (height !== 0)) {
          context.strokeRect(centerX - 0.5 * width, centerY - 0.5 * height, width, height);
        } else {
          context.fillRect(centerX - 2.0, centerY - 2.0, 4.0, 4.0);
        }

        return;
      }
    } else if (this.touchStartSquareCorner) {
      let cornerX = [this.convertMapToCanvasCoordinateX(this.touchCoordinateX),
                     this.convertMapToCanvasCoordinateX(this.touchStartCoordinateX)];
      let cornerY = [this.convertMapToCanvasCoordinateY(this.touchCoordinateY),
                     this.convertMapToCanvasCoordinateY(this.touchStartCoordinateY)];

      let centerX = 0.5 * (cornerX[0] + cornerX[1]);
      let width = cornerX[1] - cornerX[0];

      let centerY = 0.5 * (cornerY[0] + cornerY[1]);
      let height = cornerY[1] - cornerY[0];

      if ((width !== 0) || (height !== 0)) {
        context.strokeRect(centerX - 0.5 * width, centerY - 0.5 * height, width, height);
      } else {
        context.fillRect(centerX - 2.0, centerY - 2.0, 4.0, 4.0);
      }

      return;
    }

    let cornerX = [this.convertMapToCanvasCoordinateX(Number(this.squareCoordinates.current.x[0].current.value)),
                   this.convertMapToCanvasCoordinateX(Number(this.squareCoordinates.current.x[1].current.value))];
    let cornerY = [this.convertMapToCanvasCoordinateY(Number(this.squareCoordinates.current.y[0].current.value)),
                   this.convertMapToCanvasCoordinateY(Number(this.squareCoordinates.current.y[1].current.value))];

    let centerX = 0.5 * (cornerX[0] + cornerX[1]);
    let width = cornerX[1] - cornerX[0];

    let centerY = 0.5 * (cornerY[0] + cornerY[1]);
    let height = cornerY[1] - cornerY[0];

    if ((width !== 0) || (height !== 0)) {
      for (let i = 0; i < 9; i++) {
        if (i > 0) {
          width *= Number(this.phases[i].current.shrink.current.value);
          height *= Number(this.phases[i].current.shrink.current.value);
        }

        context.strokeRect(centerX - 0.5 * width, centerY - 0.5 * height, width, height);
      }
    } else {
      context.fillRect(centerX - 2.0, centerY - 2.0, 4.0, 4.0);
    }
  }

  drawTeamDeathmatches() {
    if (this.minimapCanvas.current == null) { return; }

    const context = this.minimapCanvas.current.getContext('2d', { alpha: false });

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    context.strokeStyle = 'rgba(255, 255, 255, 0.8)';
    context.lineWidth = 2.0;

    context.beginPath();

    for (let i = 0; i < this.battlegroundTeamDeathmatches.length; i++) {
      context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].coordinates[0][0]),
                     this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].coordinates[0][1]));

      for (let j = 1; j < this.battlegroundTeamDeathmatches[i].coordinates.length; j++) {
        context.lineTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].coordinates[j][0]),
                       this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].coordinates[j][1]));
      }

      context.lineTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].coordinates[0][0]),
                     this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].coordinates[0][1]));
    }

    context.closePath();

    context.stroke();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    context.fillStyle = 'rgba(12, 53, 162, 0.3)';

    context.strokeStyle = 'rgba(12, 53, 162, 0.8)';
    context.lineWidth = 2.0;

    context.beginPath();

    for (let i = 0; i < this.battlegroundTeamDeathmatches.length; i++) {
      for (let j = 0; j < this.battlegroundTeamDeathmatches[i].spawnPoints.length; j++) {
        if (this.battlegroundTeamDeathmatches[i].spawnPoints[j].team === "Honor") {
          context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[0] + this.battlegroundTeamDeathmatches[i].spawnPoints[j].radius),
                         this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[1]));

          context.arc(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[0]),
                      this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[1]),
                      this.minimapScale * this.battlegroundTeamDeathmatches[i].spawnPoints[j].radius * this.minimapCanvas.current.width,
                      0, 2 * Math.PI);
        }
      }
    }

    context.closePath();

    context.fill();

    context.stroke();

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    context.fillStyle = 'rgba(166, 14, 14, 0.3)';

    context.strokeStyle = 'rgba(166, 14, 14, 0.8)';
    context.lineWidth = 2.0;

    context.beginPath();

    for (let i = 0; i < this.battlegroundTeamDeathmatches.length; i++) {
      for (let j = 0; j < this.battlegroundTeamDeathmatches[i].spawnPoints.length; j++) {
        if (this.battlegroundTeamDeathmatches[i].spawnPoints[j].team === "Courage") {
          context.moveTo(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[0] + this.battlegroundTeamDeathmatches[i].spawnPoints[j].radius),
                         this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[1]));

          context.arc(this.convertMapToCanvasCoordinateX(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[0]),
                      this.convertMapToCanvasCoordinateY(this.battlegroundTeamDeathmatches[i].spawnPoints[j].coordinate[1]),
                      this.minimapScale * this.battlegroundTeamDeathmatches[i].spawnPoints[j].radius * this.minimapCanvas.current.width,
                      0, 2 * Math.PI);
        }
      }
    }

    context.closePath();

    context.fill();

    context.stroke();
  }

  fetchErrorSpacesData() {
    fetch('/data/errorspaces.min.json')
      .then(response => response.json())
      .then(errorSpacesData => this.parseErrorSpacesData(errorSpacesData));
  }

  fetchFixedEndCirclesData() {
    fetch('/data/endcirclelocation.min.json')
      .then(response => response.json())
      .then(fixedEndCirclesData => this.parseFixedEndCirclesData(fixedEndCirclesData));
  }

  fetchIntenseBattleRoyaleData() {
    fetch('/data/ibr.min.json')
      .then(response => response.json())
      .then(intenseBattleRoyaleData => this.parseIntenseBattleRoyaleData(intenseBattleRoyaleData));
  }

  fetchParamoRandomData() {
    fetch('/data/paramo_random.min.json')
      .then(response => response.json())
      .then(paramoRandomData => this.parseParamoRandomData(paramoRandomData));
  }

  fetchPassData() {
    fetch('/data/pass.min.json')
      .then(response => response.json())
      .then(passData => this.parsePassData(passData));
  }

  fetchTeamDeathmatchData() {
    fetch('/data/tdm.min.json')
      .then(response => response.json())
      .then(teamDeathmatchData => this.parseTeamDeathmatchData(teamDeathmatchData));
  }

  getBattleScale() {
    if (this.battleScale.current != null) {
      return this.battleScale.current.value;
    } else {
      return "Team";
    }
  }

  getMode() {
    if (this.mode.current != null) {
      return this.mode.current.value;
    } else {
      return "Normal";
    }
  }

  getShape() {
    if (this.shape.current != null) {
      return this.shape.current.value;
    } else {
      return "Circle";
    }
  }

  handleMouseDown = (event) => {
    if (this.touchRecentlyEnded && !this.touchRecentlyMoved) {
      let mode = this.getMode();

      if (mode === "Normal") {
        let tapCoordinateX = (this.touchStartMinimapTouchX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
        tapCoordinateX = tapCoordinateX.toFixed(2);
        let tapCoordinateY = (this.touchStartMinimapTouchY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);
        tapCoordinateY = tapCoordinateY.toFixed(2);

        if (this.shape.current != null) {
          if (this.shape.current.value === "Circle") {
            if (this.circleCenterCoordinate.current != null) {
              if ((this.circleCenterCoordinate.current.x.current != null) && (this.circleCenterCoordinate.current.y.current != null)) {
                this.circleCenterCoordinate.current.x.current.value = tapCoordinateX;
                this.circleCenterCoordinate.current.y.current.value = tapCoordinateY;

                if (this.mounted) {
                  this.drawCanvas(true);
                } else {
                  this.setState({});
                }
              }
            }
          } else if (this.shape.current.value === "Square") {
            if (this.squareCoordinates.current != null) {
              if ((this.squareCoordinates.current.x[0].current != null) && (this.squareCoordinates.current.y[0].current != null) &&
                  (this.squareCoordinates.current.x[1].current != null) && (this.squareCoordinates.current.y[1].current != null)) {
                const squareWidth = Math.abs(Number(this.squareCoordinates.current.x[1].current.value) - Number(this.squareCoordinates.current.x[0].current.value));
                const squareHeight = Math.abs(Number(this.squareCoordinates.current.y[1].current.value) - Number(this.squareCoordinates.current.y[0].current.value));

                let halfSquareWidth = 0.5 * squareWidth;
                halfSquareWidth = halfSquareWidth.toFixed(2);
                let halfSquareHeight = 0.5 * squareHeight;
                halfSquareHeight = halfSquareHeight.toFixed(2);

                let squareCoordinatesX = [Math.max(tapCoordinateX - halfSquareWidth, 0.0), 0.0];
                squareCoordinatesX[1] = squareCoordinatesX[0] + squareWidth;
                if (squareCoordinatesX[1] > 1.0) {
                  squareCoordinatesX[0] = 1.0 - squareWidth;
                  squareCoordinatesX[1] = 1.0;
                }
                this.squareCoordinates.current.x[0].current.value = squareCoordinatesX[0].toFixed(2);
                this.squareCoordinates.current.x[1].current.value = squareCoordinatesX[1].toFixed(2);

                let squareCoordinatesY = [Math.max(tapCoordinateY - halfSquareHeight, 0.0), 0.0];
                squareCoordinatesY[1] = squareCoordinatesY[0] + squareHeight;
                if (squareCoordinatesY[1] > 1.0) {
                  squareCoordinatesY[0] = 1.0 - squareHeight;
                  squareCoordinatesY[1] = 1.0;
                }
                this.squareCoordinates.current.y[0].current.value = squareCoordinatesY[0].toFixed(2);
                this.squareCoordinates.current.y[1].current.value = squareCoordinatesY[1].toFixed(2);

                if (this.mounted) {
                  this.drawCanvas(true);
                }

                this.updateAnnotations();
              }
            }
          }
        }
      }
    } else if (this.minimapMouseHover) {
      event.stopPropagation();
      event.preventDefault();

      if (event.shiftKey) {
        this.shiftClickCoordinateX = (this.minimapMouseX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
        this.shiftClickCoordinateX = this.shiftClickCoordinateX.toFixed(2);
        this.shiftClickCoordinateY = (this.minimapMouseY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);
        this.shiftClickCoordinateY = this.shiftClickCoordinateY.toFixed(2);

        this.shiftDragCoordinateX = this.shiftClickCoordinateX;
        this.shiftDragCoordinateY = this.shiftClickCoordinateY;

        let mode = this.getMode();

        if (mode === "Normal") {
          if (this.shape.current != null) {
            if (this.shape.current.value === "Circle") {
              if (this.circleCenterCoordinate.current != null) {
                if ((this.circleCenterCoordinate.current.x.current != null) && (this.circleCenterCoordinate.current.y.current != null)) {
                  this.circleCenterCoordinate.current.x.current.value = this.shiftClickCoordinateX;
                  this.circleCenterCoordinate.current.y.current.value = this.shiftClickCoordinateY;

                  if (this.mounted) {
                    this.drawCanvas(true);
                  } else {
                    this.setState({});
                  }
                }
              }
            }
          }
        }
      } else if (event.ctrlKey || event.altKey) {
        this.ctrlAltClickCoordinateX = (this.minimapMouseX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
        this.ctrlAltClickCoordinateX = this.ctrlAltClickCoordinateX.toFixed(2);
        this.ctrlAltClickCoordinateY = (this.minimapMouseY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);
        this.ctrlAltClickCoordinateY = this.ctrlAltClickCoordinateY.toFixed(2);
        
        this.ctrlAltDragCoordinateX = this.ctrlAltClickCoordinateX;
        this.ctrlAltDragCoordinateY = this.ctrlAltClickCoordinateY;
      }

      this.mouseDownMinimapMouseX = this.minimapMouseX;
      this.mouseDownMinimapMouseY = this.minimapMouseY;

      this.mouseDownMinimapOffsetX = this.minimapOffsetX;
      this.mouseDownMinimapOffsetY = this.minimapOffsetY;

      this.mouseDownShiftKey = event.shiftKey;
      this.mouseDownCtrlAltKey = event.ctrlKey || event.altKey;
      this.mouseDown = true;
    }
  }

  handleMouseMove = (event) => {
    if (this.minimapCanvas.current == null) { return; }

    this.minimapMouseX = event.pageX - this.minimapCanvas.current.offsetLeft;
    this.minimapMouseY = event.pageY - this.minimapCanvas.current.offsetTop;

    if (this.mouseDown) {
      event.stopPropagation();
      event.preventDefault();

      if (this.mouseDownShiftKey) {
        if (event.shiftKey) {
          this.shiftDragCoordinateX = (this.minimapMouseX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
          this.shiftDragCoordinateX = this.shiftDragCoordinateX.toFixed(2);
          this.shiftDragCoordinateY = (this.minimapMouseY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);
          this.shiftDragCoordinateY = this.shiftDragCoordinateY.toFixed(2);

          if (this.mounted) { this.drawCanvas(false); }
        }
      } else if (this.mouseDownCtrlAltKey) {
        if (event.ctrlKey || event.altKey) {
          this.ctrlAltDragCoordinateX = (this.minimapMouseX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
          this.ctrlAltDragCoordinateX = this.ctrlAltDragCoordinateX.toFixed(2);
          this.ctrlAltDragCoordinateY = (this.minimapMouseY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);
          this.ctrlAltDragCoordinateY = this.ctrlAltDragCoordinateY.toFixed(2);

          if (this.mounted) { this.drawCanvas(false); }
        }
      } else {
        this.minimapOffsetX = this.mouseDownMinimapOffsetX + this.minimapMouseX - this.mouseDownMinimapMouseX;
        this.minimapOffsetY = this.mouseDownMinimapOffsetY + this.minimapMouseY - this.mouseDownMinimapMouseY;

        this.restrictMinimapOffset();

        if (this.mounted) { this.drawCanvas(false); }
      }
    }

    this.updateMinimapImageLoadingPriorities();

    if (this.minimapMouseHover) {
      this.clientMouseX = event.clientX;
      this.clientMouseY = event.clientY;

      this.mouseMoveCoordinateX = (this.minimapMouseX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
      this.mouseMoveCoordinateY = (this.minimapMouseY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);

      this.setMouseMoveCoordinatesLabel();

      this.setState({});
    }
  }

  handleMouseUp = (event) => {
    if (this.mouseDown) {
      event.stopPropagation();
      event.preventDefault();

      this.mouseDown = false;

      if (this.mouseDownShiftKey) {
        let mode = this.getMode();

        if (mode === "Normal") {
          if (this.shape.current != null) {
            if (this.shape.current.value === "Square") {
              if (this.squareCoordinates.current != null) {
                if ((this.squareCoordinates.current.x[0].current != null) && (this.squareCoordinates.current.y[0].current != null) &&
                    (this.squareCoordinates.current.x[1].current != null) && (this.squareCoordinates.current.y[1].current != null)) {
                  if (this.shiftClickCoordinateX < this.shiftDragCoordinateX) {
                    this.squareCoordinates.current.x[0].current.value = this.shiftClickCoordinateX;
                    this.squareCoordinates.current.x[1].current.value = this.shiftDragCoordinateX;
                  } else {
                    this.squareCoordinates.current.x[0].current.value = this.shiftDragCoordinateX;
                    this.squareCoordinates.current.x[1].current.value = this.shiftClickCoordinateX;
                  }

                  if (this.shiftClickCoordinateY < this.shiftDragCoordinateY) {
                    this.squareCoordinates.current.y[0].current.value = this.shiftClickCoordinateY;
                    this.squareCoordinates.current.y[1].current.value = this.shiftDragCoordinateY;
                  } else {
                    this.squareCoordinates.current.y[0].current.value = this.shiftDragCoordinateY;
                    this.squareCoordinates.current.y[1].current.value = this.shiftClickCoordinateY;
                  }

                  if (this.mounted) {
                    this.drawCanvas(true);
                  }

                  this.updateAnnotations();
                }
              }
            }
          }
        }
      } else if (this.mouseDownCtrlAltKey) {
        let mode = this.getMode();

        if (mode === "Normal") {
          if (this.shape.current != null) {
            if (this.shape.current.value === "Square") {
              if (this.squareCoordinates.current != null) { 
                if ((this.squareCoordinates.current.x[0].current != null) && (this.squareCoordinates.current.y[0].current != null) &&
                    (this.squareCoordinates.current.x[1].current != null) && (this.squareCoordinates.current.y[1].current != null)) {
                  if (this.ctrlAltClickCoordinateX < this.ctrlAltDragCoordinateX) {
                    this.squareCoordinates.current.x[0].current.value = 2.0 * this.ctrlAltClickCoordinateX - this.ctrlAltDragCoordinateX;
                    this.squareCoordinates.current.x[1].current.value = this.ctrlAltDragCoordinateX;
                  } else {
                    this.squareCoordinates.current.x[0].current.value = this.ctrlAltDragCoordinateX;
                    this.squareCoordinates.current.x[1].current.value = 2.0 * this.ctrlAltClickCoordinateX - this.ctrlAltDragCoordinateX;
                  }

                  if (this.ctrlAltClickCoordinateY < this.ctrlAltDragCoordinateY) {
                    this.squareCoordinates.current.y[0].current.value = 2.0 * this.ctrlAltClickCoordinateY - this.ctrlAltDragCoordinateY;
                    this.squareCoordinates.current.y[1].current.value = this.ctrlAltDragCoordinateY;
                  } else {
                    this.squareCoordinates.current.y[0].current.value = this.ctrlAltDragCoordinateY;
                    this.squareCoordinates.current.y[1].current.value = 2.0 * this.ctrlAltClickCoordinateY - this.ctrlAltDragCoordinateY;
                  }

                  if (this.mounted) {
                    this.drawCanvas(true);
                  }

                  this.updateAnnotations();
                }
              }
            }
          }
        }
      }

      this.setSearchParameters();
    }
  }

  handleResize = (event) => {
    this.updateMinimapDimensions();
  }

  handleSetMinimapViewportClick = (battleground, minimapScale, minimapOffsetX, minimapOffsetY, event) => {
    this.battleground.current.value = battleground;

    this.selectBattleground();

    this.setMinimapViewport(minimapScale, minimapOffsetX, minimapOffsetY);

    this.drawCanvas(true);

    this.setSearchParameters();
  }

  minimapScaleDecrement(minimapScale) {
    let scaleStep = 0.1;
    let scaleRange = 2.0;

    while (minimapScale > scaleRange) {
      scaleStep *= 2.0;
      scaleRange *= 2.0;
    }

    return scaleStep;
  }

  minimapScaleIncrement(minimapScale) {
    let scaleStep = 0.1;
    let scaleRange = 2.0;

    while (minimapScale >= scaleRange) {
      scaleStep *= 2.0;
      scaleRange *= 2.0;
    }

    return scaleStep;
  }

  mouseEnterMinimap = (event) => {
    if (!this.touchRecentlyEnded) {
      this.minimapMouseHover = true;

      this.clientMouseX = event.clientX;
      this.clientMouseY = event.clientY;

      this.mouseMoveCoordinateX = (this.minimapMouseX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
      this.mouseMoveCoordinateY = (this.minimapMouseY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);

      this.setMouseMoveCoordinatesLabel();
    }
  }

  mouseLeaveMinimap = (event) => {
    if (!this.touchRecentlyEnded) {
      this.minimapMouseHover = false;

      this.setState({});
    }
  }

  mouseMoveMinimap = (event) => {
    if (!this.touchRecentlyEnded) {
      this.mouseMinimapX = event.nativeEvent.offsetX;
      this.mouseMinimapY = event.nativeEvent.offsetY;
    }
  }

  parseCapturePointsData(capturePointsData, width, height, adjustCoordinates) {
    let capturePoints = [];

    for (let i = 0; i < capturePointsData.length; i++) {
      const capturePointCoordinate = [(capturePointsData[i].location[0] + adjustCoordinates[0]) / width,
                                      (capturePointsData[i].location[1] + adjustCoordinates[1]) / height];
      const capturePointRadius = 7.5 / width;

      capturePoints.push(new CapturePoint(capturePointsData[i].displayName, capturePointCoordinate, capturePointRadius));
    }

    return capturePoints;
  }

  parseErrorSpacesData(errorSpacesData) {
    const battlegrounds = Object.keys(errorSpacesData);

    for (let i = 0; i < battlegrounds.length; i++) {
      const battlegroundErrorSpaces = errorSpacesData[battlegrounds[i]];

      const battlegroundWidth = this.battlegrounds[battlegrounds[i]].width;
      const battlegroundHeight = this.battlegrounds[battlegrounds[i]].height;

      const battlegroundAdjustCoordinates = this.battlegrounds[battlegrounds[i]].adjustCoordinates;

      for (let j = 0; j < battlegroundErrorSpaces.length; j++) {
        this.errorSpaces.push(new ErrorSpace(battlegrounds[i],
                                             battlegroundErrorSpaces[j]._label,
                                             battlegroundErrorSpaces[j].overrideType,
                                             [(battlegroundErrorSpaces[j].location[0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                                              (battlegroundErrorSpaces[j].location[1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight],
                                             battlegroundErrorSpaces[j].radius / battlegroundWidth));
      }
    }

    this.setState({});

    this.setBattlegroundErrorSpaces();

    if (this.mounted) {
      this.drawCanvas(false);
    }
  }

  parseFixedEndCirclesData(fixedEndCirclesData) {
    const battlegrounds = Object.keys(fixedEndCirclesData);

    for (let i = 0; i < battlegrounds.length; i++) {
      const battlegroundFixedEndCircles = fixedEndCirclesData[battlegrounds[i]];

      const battlegroundWidth = this.battlegrounds[battlegrounds[i]].width;
      const battlegroundHeight = this.battlegrounds[battlegrounds[i]].height;

      const battlegroundAdjustCoordinates = this.battlegrounds[battlegrounds[i]].adjustCoordinates;

      let totalWeight = 0;
      for (let j = 0; j < battlegroundFixedEndCircles["Field"].length; j++) { totalWeight += battlegroundFixedEndCircles["Field"][j].weight; }

      for (let j = 0; j < battlegroundFixedEndCircles["Field"].length; j++) {
        this.fixedEndFieldCircles.push(new FixedEndCircle(battlegrounds[i],
                                                          [(battlegroundFixedEndCircles["Field"][j].location[0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                                                           (battlegroundFixedEndCircles["Field"][j].location[1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight],
                                                          battlegroundFixedEndCircles["Field"][j].radius / battlegroundWidth,
                                                          this.convertWeightToPercentChance(battlegroundFixedEndCircles["Field"][j].weight, totalWeight)));
      }

      totalWeight = 0;
      for (let j = 0; j < battlegroundFixedEndCircles["Mountain"].length; j++) { totalWeight += battlegroundFixedEndCircles["Mountain"][j].weight; }

      for (let j = 0; j < battlegroundFixedEndCircles["Mountain"].length; j++) {
        this.fixedEndMountainCircles.push(new FixedEndCircle(battlegrounds[i],
                                                             [(battlegroundFixedEndCircles["Mountain"][j].location[0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                                                              (battlegroundFixedEndCircles["Mountain"][j].location[1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight],
                                                             battlegroundFixedEndCircles["Mountain"][j].radius / battlegroundWidth,
                                                             this.convertWeightToPercentChance(battlegroundFixedEndCircles["Mountain"][j].weight, totalWeight)));
      }

      totalWeight = 0;
      for (let j = 0; j < battlegroundFixedEndCircles["Town"].length; j++) { totalWeight += battlegroundFixedEndCircles["Town"][j].weight; }

      for (let j = 0; j < battlegroundFixedEndCircles["Town"].length; j++) {
        this.fixedEndTownCircles.push(new FixedEndCircle(battlegrounds[i],
                                                         [(battlegroundFixedEndCircles["Town"][j].location[0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                                                          (battlegroundFixedEndCircles["Town"][j].location[1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight],
                                                         battlegroundFixedEndCircles["Town"][j].radius / battlegroundWidth,
                                                         this.convertWeightToPercentChance(battlegroundFixedEndCircles["Town"][j].weight, totalWeight)));
      }
    }

    this.setState({});

    this.setBattlegroundFixedEndCircles();

    if (this.mounted) {
      this.drawCanvas(false);
    }
  }

  parseIntenseBattleRoyaleData(intenseBattleRoyaleData) {
    const battlegrounds = Object.keys(intenseBattleRoyaleData);

    for (let i = 0; i < battlegrounds.length; i++) {
      const intenseBattleRoyales = intenseBattleRoyaleData[battlegrounds[i]];

      const battlegroundWidth = this.battlegrounds[battlegrounds[i]].width;
      const battlegroundHeight = this.battlegrounds[battlegrounds[i]].height;

      const battlegroundAdjustCoordinates = this.battlegrounds[battlegrounds[i]].adjustCoordinates;

      let totalWeight = 0;
      for (let j = 0; j < intenseBattleRoyales.length; j++) {
        totalWeight += intenseBattleRoyales[j].weight;
      }

      for (let j = 0; j < intenseBattleRoyales.length; j++) {
        this.intenseBattleRoyales.push(new IntenseBattleRoyale(battlegrounds[i],
                                                               intenseBattleRoyales[j]._label,
                                                               [(intenseBattleRoyales[j].location[0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                                                                (intenseBattleRoyales[j].location[1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight],
                                                               intenseBattleRoyales[j].radius / battlegroundWidth,
                                                               this.convertWeightToPercentChance(intenseBattleRoyales[j].weight, totalWeight)));
      }
    }

    this.setState({});

    this.setBattlegroundIntenseBattleRoyales();

    if (this.mounted) {
      this.drawCanvas(false);
    }
  }

  parseParamoRandomData(paramoRandomData) {
    const minimap = this.battlegrounds["Paramo"].minimap;

    const randomElementsData = paramoRandomData["levelBlocks"];

    for (let i = 0; i < randomElementsData.length; i++) {
      minimap.randomElements.push(new MinimapRandomElement(randomElementsData[i]._label,
                                                           minimap.imageOnload.bind(minimap),
                                                           randomElementsData[i].terrainOverlay.textureName, randomElementsData[i].terrainOverlay.relLocationPx,
                                                           randomElementsData[i].objectOverlay.textureName, randomElementsData[i].objectOverlay.relLocationPx,
                                                           randomElementsData[i].labelOverlay.textureName, randomElementsData[i].labelOverlay.relLocationPx));
    }

    const randomOverlayLocationsData = paramoRandomData["levelBlockSpawnPoints"];

    for (let i = 0; i < randomOverlayLocationsData.length; i++) {
      minimap.randomOverlayLocations.push(new MinimapRandomOverlayLocation(i, randomOverlayLocationsData[i].locationPx));
    }

    const randomBaseLocationsData = paramoRandomData["baseMapLocations"];

    for (let i = 0; i < randomBaseLocationsData.length; i++) {
      minimap.randomBaseLocations[randomBaseLocationsData[i].labelOverlay.textureName] = new MinimapRandomBaseLocation(randomBaseLocationsData[i].labelOverlay.locationPx);
    }

    if (this.battleground.current != null) {
      if ((this.battleground.current.value === "Paramo") && (!minimap.loadingImages)) {
        minimap.loadImages();
      }
    }
  }

  parsePassData(passData) {
    const battlegrounds = Object.keys(passData);

    for (let i = 0; i < battlegrounds.length; i++) {
      const battlegroundPasses = passData[battlegrounds[i]];

      const battlegroundWidth = this.battlegrounds[battlegrounds[i]].width;
      const battlegroundHeight = this.battlegrounds[battlegrounds[i]].height;

      const battlegroundAdjustCoordinates = this.battlegrounds[battlegrounds[i]].adjustCoordinates;

      for (let j = 0; j < battlegroundPasses.length; j++) {
        if (battlegroundPasses[j].type === "circle") {
          this.passes.push(new PassCircle(battlegrounds[i],
                                          [(battlegroundPasses[j].location[0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                                           (battlegroundPasses[j].location[1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight],
                                          battlegroundPasses[j].radius / battlegroundWidth));
        } else if (battlegroundPasses[j].type === "rectangle") {
          let coordinates = [];
          for (let k = 0; k < battlegroundPasses[j].boundaryVertices.length; k++) {
            coordinates.push([(battlegroundPasses[j].boundaryVertices[k][0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                              (battlegroundPasses[j].boundaryVertices[k][1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight]);
          }

          this.passes.push(new PassPolygon(battlegrounds[i],
                                           coordinates));
        }
      }
    }

    this.setState({});

    this.setBattlegroundPasses();

    if (this.mounted) {
      this.drawCanvas(false);
    }
  }

  parseSpawnPointsData(spawnPointsData, width, height, adjustCoordinates) {
    let spawnPoints = [];

    for (let i = 0; i < spawnPointsData.length; i++) {
      const spawnPointCoordinate = [(spawnPointsData[i].location[0] + adjustCoordinates[0]) / width,
                                    (spawnPointsData[i].location[1] + adjustCoordinates[1]) / height];
      const spawnPointRadius = spawnPointsData[i].spawnRadius ? spawnPointsData[i].spawnRadius / width : 2.0 / width;

      spawnPoints.push(new SpawnPoint(spawnPointsData[i].team, spawnPointCoordinate, spawnPointRadius));
    }

    return spawnPoints;
  }

  parseTeamDeathmatchData(teamDeathmatchData) {
    const battlegrounds = Object.keys(teamDeathmatchData);

    for (let i = 0; i < battlegrounds.length; i++) {
      const teamDeathmatches = teamDeathmatchData[battlegrounds[i]];

      const battlegroundWidth = this.battlegrounds[battlegrounds[i]].width;
      const battlegroundHeight = this.battlegrounds[battlegrounds[i]].height;

      const battlegroundAdjustCoordinates = this.battlegrounds[battlegrounds[i]].adjustCoordinates;

      for (let j = 0; j < teamDeathmatches.length; j++) {
        if (teamDeathmatches[j].boundaryVertices.length < 3) { continue; }

        let coordinates = [];
        for (let k = 0; k < teamDeathmatches[j].boundaryVertices.length; k++) {
          coordinates.push([(teamDeathmatches[j].boundaryVertices[k][0] + battlegroundAdjustCoordinates[0]) / battlegroundWidth,
                            (teamDeathmatches[j].boundaryVertices[k][1] + battlegroundAdjustCoordinates[1]) / battlegroundHeight]);
        }

        const spawnPoints = this.parseSpawnPointsData(teamDeathmatches[j].spawnPoints,
                                                      battlegroundWidth,
                                                      battlegroundHeight,
                                                      battlegroundAdjustCoordinates);

        this.teamDeathmatches.push(new TeamDeathmatch(battlegrounds[i],
                                                      teamDeathmatches[j]._label,
                                                      coordinates,
                                                      spawnPoints,
                                                      this.battlegroundMaximumMinimapScale(battlegrounds[i]), this.minimapScaleDecrement));
      }
    }

    this.setState({});

    this.setBattlegroundTeamDeathmatches();

    if (this.mounted) {
      this.drawCanvas(false);
    }
  }

  restrictMinimapOffset() {
    if (isNaN(this.minimapOffsetY)) {
      this.minimapOffsetY = 0;
    } else if (this.minimapOffsetY > 0) {
      this.minimapOffsetY = 0;
    } else if (this.minimapCanvas.current != null) {
      if (this.minimapOffsetY < this.minimapCanvas.current.height * (1.0 - this.minimapScale)) {
        this.minimapOffsetY = this.minimapCanvas.current.height * (1.0 - this.minimapScale);
      }
    }

    if (isNaN(this.minimapOffsetX)) {
      this.minimapOffsetX = 0;
    } else if (this.minimapOffsetX > 0) {
      this.minimapOffsetX = 0;
    } else if (this.minimapCanvas.current != null) {
      if (this.minimapOffsetX < this.minimapCanvas.current.width * (1.0 - this.minimapScale)) {
        this.minimapOffsetX = this.minimapCanvas.current.width * (1.0 - this.minimapScale);
      }
    }
  }

  restrictMinimapScale() {
    if (isNaN(this.minimapScale)) {
      this.minimapScale = 1.0;
    } else if (this.minimapScale < 1.0) {
      this.minimapScale = 1.0;
    } else if (this.minimapScale > this.maximumMinimapScale) {
      this.minimapScale = this.maximumMinimapScale;
    }
  }

  scaleMinimap = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (this.minimapCanvas.current == null) { return; }

    const previousMinimapScale = this.minimapScale;

    if (event.deltaY < 0) {
      this.minimapScale += this.minimapScaleIncrement(this.minimapScale);
    } else {
      this.minimapScale -= this.minimapScaleDecrement(this.minimapScale);
    }

    this.restrictMinimapScale();

    if (this.minimapScale === previousMinimapScale) { return; }

    const previousMinimapOffsetX = this.minimapOffsetX;
    const previousMinimapOffsetY = this.minimapOffsetY;

    this.minimapOffsetX += (this.mouseMinimapX - this.minimapOffsetX) * (previousMinimapScale - this.minimapScale) / previousMinimapScale;
    this.minimapOffsetY += (this.mouseMinimapY - this.minimapOffsetY) * (previousMinimapScale - this.minimapScale) / previousMinimapScale;

    this.restrictMinimapOffset();

    if (this.mounted) {
      this.drawCanvas(false);
    }

    if ((previousMinimapOffsetX !== this.minimapOffsetX) || (previousMinimapOffsetY !== this.minimapOffsetY)) {
      if (this.mouseDown) {
        this.mouseDownMinimapMouseX = this.minimapMouseX;
        this.mouseDownMinimapMouseY = this.minimapMouseY;

        this.mouseDownMinimapOffsetX = this.minimapOffsetX;
        this.mouseDownMinimapOffsetY = this.minimapOffsetY;
      }

      this.mouseMoveCoordinateX = (this.minimapMouseX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
      this.mouseMoveCoordinateY = (this.minimapMouseY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);

      this.setMouseMoveCoordinatesLabel();

      this.setState({});
    }

    this.updateMinimapImageLoadingPriorities();

    window.clearTimeout (this.wheelTimeoutID);

    this.wheelTimeoutID = window.setTimeout(this.setSearchParametersTimeout, 100, this);
  }

  selectBattleground = (event) => {
    if (this.battleground.current == null) { return; }

    let battlegrounds = Object.keys(this.battlegrounds);
    for (let i = 0; i < battlegrounds.length; i++) { this.battlegrounds[battlegrounds[i]].minimap.loadingImages = false; }

    this.battlegrounds[this.battleground.current.value].minimap.loadImages();

    this.updateMinimapImageLoadingPriorities();

    this.setBattlegroundErrorSpaces();

    this.setBattlegroundFixedEndCircles();

    this.setBattlegroundPasses();

    this.setBattlegroundTeamDeathmatches();

    this.setBattlegroundIntenseBattleRoyales();

    this.maximumMinimapScale = this.battlegroundMaximumMinimapScale(this.battleground.current.value);

    if (this.mounted) {
      this.drawCanvas(false);

      let mode = this.getMode();

      if (mode === "Normal") {
        this.updateAnnotations();
      } else {
        this.setState({});
      }
    }

    this.setSearchParameters();
  }

  selectMode = (event) => {
    if (this.mode.current == null) { return; }

    if (this.mounted) { this.drawCanvas(false); }

    this.setSearchParameters();

    this.setState({});

    window.setTimeout(this.updateMinimapDimensionsTimeout, 0, this);
  }

  selectShape = (event) => {
    if (this.mounted) {
      this.drawCanvas(false);

      this.updateAnnotations();
    } else {
      this.setState({});
    }
  }

  setBattlegroundErrorSpaces() {
    if (this.battleground.current == null) { return; }

    this.battlegroundErrorSpaces = [];

    for (let i = 0; i < this.errorSpaces.length; i++) {
      if (this.errorSpaces[i].battleground === this.battleground.current.value) {
        this.battlegroundErrorSpaces.push(this.errorSpaces[i]);
      }
    }
  }

  setBattlegroundFixedEndCircles() {
    if (this.battleground.current == null) { return; }

    this.battlegroundFixedEndFieldCircles = [];

    for (let i = 0; i < this.fixedEndFieldCircles.length; i++) {
      if (this.fixedEndFieldCircles[i].battleground === this.battleground.current.value) {
        this.battlegroundFixedEndFieldCircles.push(this.fixedEndFieldCircles[i]);
      }
    }

    this.battlegroundFixedEndMountainCircles = [];

    for (let i = 0; i < this.fixedEndMountainCircles.length; i++) {
      if (this.fixedEndMountainCircles[i].battleground === this.battleground.current.value) {
        this.battlegroundFixedEndMountainCircles.push(this.fixedEndMountainCircles[i]);
      }
    }

    this.battlegroundFixedEndTownCircles = [];

    for (let i = 0; i < this.fixedEndTownCircles.length; i++) {
      if (this.fixedEndTownCircles[i].battleground === this.battleground.current.value) {
        this.battlegroundFixedEndTownCircles.push(this.fixedEndTownCircles[i]);
      }
    }
  }

  setBattlegroundIntenseBattleRoyales() {
    if (this.battleground.current == null) { return; }

    this.battlegroundIntenseBattleRoyales = [];

    for (let i = 0; i < this.intenseBattleRoyales.length; i++) {
      if (this.intenseBattleRoyales[i].battleground === this.battleground.current.value) {
        this.battlegroundIntenseBattleRoyales.push(this.intenseBattleRoyales[i]);
      }
    }
  }

  setBattlegroundPasses() {
    if (this.battleground.current == null) { return; }

    this.battlegroundPasses = [];

    for (let i = 0; i < this.passes.length; i++) {
      if (this.passes[i].battleground === this.battleground.current.value) {
        this.battlegroundPasses.push(this.passes[i]);
      }
    }
  }

  setBattlegroundTeamDeathmatches() {
    if (this.battleground.current == null) { return; }

    this.battlegroundTeamDeathmatches = [];

    for (let i = 0; i < this.teamDeathmatches.length; i++) {
      if (this.teamDeathmatches[i].battleground === this.battleground.current.value) {
        this.battlegroundTeamDeathmatches.push(this.teamDeathmatches[i]);
      }
    }
  }

  setMinimapViewport(minimapScale, minimapOffsetX, minimapOffsetY) {
    let modifiedMinimapViewport = false;

    if (this.minimapScale !== minimapScale) {
      this.minimapScale = minimapScale;

      this.restrictMinimapScale();

      modifiedMinimapViewport = true;
    }

    if ((this.minimapOffsetX !== minimapOffsetX) || (this.minimapOffsetY !== minimapOffsetY)) {
      this.minimapOffsetX = minimapOffsetX;
      this.minimapOffsetY = minimapOffsetY;

      this.restrictMinimapOffset();

      modifiedMinimapViewport = true;
    }

    if (modifiedMinimapViewport) { this.updateMinimapImageLoadingPriorities(); }
  }

  setMouseMoveCoordinatesLabel() {
    this.mouseMoveCoordinatesLabel = "";

    let mode = this.getMode();

    if ((mode === "-") || (mode === "Normal")) {
      if (this.showErrorSpaces.current != null) {
        if (this.showErrorSpaces.current.checked) {
          for (let i = 0; i < this.battlegroundErrorSpaces.length; i++) {
            if (this.battlegroundErrorSpaces[i].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
              this.mouseMoveCoordinatesLabel = this.battlegroundErrorSpaces[i].label + " Error Space\n" + this.battlegroundErrorSpaces[i].battlegroundLike;

              break;
            }
          }
        }
      }

      if (this.showPasses.current != null) {
        if (this.showPasses.current.checked) {
          for (let i = 0; i < this.battlegroundPasses.length; i++) {
            if (this.battlegroundPasses[i].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
              if (this.mouseMoveCoordinatesLabel !== "") { this.mouseMoveCoordinatesLabel += "\n"; }

              this.mouseMoveCoordinatesLabel += "Prohibited End Location";

              break;
            }
          }
        }
      }

      if (this.showFixedEndFieldCircles.current != null) {
        if (this.showFixedEndFieldCircles.current.checked) {
          for (let i = 0; i < this.battlegroundFixedEndFieldCircles.length; i++) {
            if (this.battlegroundFixedEndFieldCircles[i].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
              if (this.mouseMoveCoordinatesLabel !== "") { this.mouseMoveCoordinatesLabel += "\n"; }

              this.mouseMoveCoordinatesLabel += "Field Fixed End Location (" + this.battlegroundFixedEndFieldCircles[i].percentChance + ")";
            }
          }
        }
      }

      if (this.showFixedEndMountainCircles.current != null) {
        if (this.showFixedEndMountainCircles.current.checked) {
          for (let i = 0; i < this.battlegroundFixedEndMountainCircles.length; i++) {
            if (this.battlegroundFixedEndMountainCircles[i].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
              if (this.mouseMoveCoordinatesLabel !== "") { this.mouseMoveCoordinatesLabel += "\n"; }

              this.mouseMoveCoordinatesLabel += "Mountain Fixed End Location (" + this.battlegroundFixedEndMountainCircles[i].percentChance + ")";
            }
          }
        }
      }

      if (this.showFixedEndTownCircles.current != null) {
        if (this.showFixedEndTownCircles.current.checked) {
          for (let i = 0; i < this.battlegroundFixedEndTownCircles.length; i++) {
            if (this.battlegroundFixedEndTownCircles[i].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
              if (this.mouseMoveCoordinatesLabel !== "") { this.mouseMoveCoordinatesLabel += "\n"; }

              this.mouseMoveCoordinatesLabel += "Town Fixed End Location (" + this.battlegroundFixedEndTownCircles[i].percentChance + ")";
            }
          }
        }
      }
    } else if (mode === "TDM") {
      for (let i = 0; i < this.battlegroundTeamDeathmatches.length; i++) {
        if (this.battlegroundTeamDeathmatches[i].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
          this.mouseMoveCoordinatesLabel = this.battlegroundTeamDeathmatches[i].label;

          for (let j = 0; j < this.battlegroundTeamDeathmatches[i].spawnPoints.length; j++) {
            if (this.battlegroundTeamDeathmatches[i].spawnPoints[j].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
              this.mouseMoveCoordinatesLabel += "\n" + this.battlegroundTeamDeathmatches[i].spawnPoints[j].team + " Spawn Point";

              break;
            }
          }

          break;
        }
      }
    } else if (mode === "IntenseBR") {
      for (let i = 0; i < this.battlegroundIntenseBattleRoyales.length; i++) {
        if (this.battlegroundIntenseBattleRoyales[i].inside(this.mouseMoveCoordinateX, this.mouseMoveCoordinateY)) {
          if (this.mouseMoveCoordinatesLabel !== "") { this.mouseMoveCoordinatesLabel += "\n"; }
          this.mouseMoveCoordinatesLabel += this.battlegroundIntenseBattleRoyales[i].label + " (" + this.battlegroundIntenseBattleRoyales[i].percentChance + ")";

          break;
        }
      }
    }

    const tooltipDiv = <Tooltip display="table" visibility="hidden" position="relative" left="0" top="0" coordinate={this.mouseMoveCoordinateX.toFixed(3) + ", " + this.mouseMoveCoordinateY.toFixed(3)} label={this.mouseMoveCoordinatesLabel} />;

    tooltipMeasurementRoot.render(tooltipDiv);
  }

  setPhaseValuesForAllPhases = (event) => {
    if (this.battleground.current == null) { return; }
    if (this.battlegrounds[this.battleground.current.value] == null) { return; }

    for (let i = 0; i < 9; i++) {
      if (this.phases[i].current == null) { return; }

      if ((this.phases[i].current.delay.current == null) ||
          (this.phases[i].current.wait.current == null) ||
          (this.phases[i].current.move.current == null) ||
          (this.phases[i].current.dps.current == null) ||
          (this.phases[i].current.shrink.current == null) ||
          (this.phases[i].current.spread.current == null) ||
          (this.phases[i].current.landRatio.current == null)) {
        return;
      }
    }

    const battleground = this.battlegrounds[this.battleground.current.value];

    let phasesValues = "";
    if (battleground.phasesDefaultValues != null) { phasesValues = "Default"; }
    if (this.phasesValues.current != null) {
      if (this.phasesValues.current.value !== "") { phasesValues = this.phasesValues.current.value; }
    }

    if (phasesValues === "Default") {
      for (let i = 0; i < 9; i++) {
        this.phases[i].current.delay.current.value = battleground.phasesDefaultValues[i].delay;
        this.phases[i].current.wait.current.value = battleground.phasesDefaultValues[i].wait;
        this.phases[i].current.move.current.value = battleground.phasesDefaultValues[i].move;
        this.phases[i].current.dps.current.value = battleground.phasesDefaultValues[i].dps;
        this.phases[i].current.shrink.current.value = battleground.phasesDefaultValues[i].shrink;
        this.phases[i].current.spread.current.value = battleground.phasesDefaultValues[i].spread;
        this.phases[i].current.landRatio.current.value = battleground.phasesDefaultValues[i].landRatio;
      }
    } else {
      for (let i = 0; i < battleground.specialNormalModes.length; i++) {
        if (this.phasesValues.current.value === battleground.specialNormalModes[i].name) {
          for (let j = 0; j < battleground.specialNormalModes[i].phasesDefaultValues.length; j++) {
            this.phases[j].current.delay.current.value = battleground.specialNormalModes[i].phasesDefaultValues[j].delay;
            this.phases[j].current.wait.current.value = battleground.specialNormalModes[i].phasesDefaultValues[j].wait;
            this.phases[j].current.move.current.value = battleground.specialNormalModes[i].phasesDefaultValues[j].move;
            this.phases[j].current.dps.current.value = battleground.specialNormalModes[i].phasesDefaultValues[j].dps;
            this.phases[j].current.shrink.current.value = battleground.specialNormalModes[i].phasesDefaultValues[j].shrink;
            this.phases[j].current.spread.current.value = battleground.specialNormalModes[i].phasesDefaultValues[j].spread;
            this.phases[j].current.landRatio.current.value = battleground.specialNormalModes[i].phasesDefaultValues[j].landRatio;
          }

          for (let j = battleground.specialNormalModes[i].phasesDefaultValues.length; j < 9; j++) {
            this.phases[j].current.delay.current.value = "";
            this.phases[j].current.wait.current.value = "";
            this.phases[j].current.move.current.value = "";
            this.phases[j].current.dps.current.value = "";
            this.phases[j].current.shrink.current.value = "";
            this.phases[j].current.spread.current.value = "";
            this.phases[j].current.landRatio.current.value = "";
          }
        }
      }
    }

    if (this.mounted) {
      this.drawCanvas(false);

      this.updateAnnotations();
    } else {
      this.setState({});
    }
  }

  setSearchParameters = (event) => {
    if (this.mounted) {
      let searchParameters = "?";

      if (this.battleground.current != null) {
        if (searchParameters !== "?") { searchParameters += "&"; }

        searchParameters += "m=" + this.battleground.current.value;
      }

      if (searchParameters !== "?") { searchParameters += "&"; }

      searchParameters += "ms=" + this.minimapScale.toFixed(1);

      if (this.minimapCanvas.current != null) {
        if (searchParameters !== "?") { searchParameters += "&"; }

        searchParameters += "mx=" + ((-this.minimapOffsetX + 0.5 * this.minimapCanvas.current.width) / (this.minimapScale * this.minimapCanvas.current.width)).toFixed(4);

        if (searchParameters !== "?") { searchParameters += "&"; }

        searchParameters += "my=" + ((-this.minimapOffsetY + 0.5 * this.minimapCanvas.current.height) / (this.minimapScale * this.minimapCanvas.current.height)).toFixed(4);
      }

      if (this.mode.current != null) {
        if (searchParameters !== "?") { searchParameters += "&"; }

        searchParameters += "mo=" + this.mode.current.value;
      }

      let mode = this.getMode();

      if (mode === "Normal") {
        if (this.shape.current != null) {
          if (searchParameters !== "?") { searchParameters += "&"; }

          searchParameters += "s=" + this.shape.current.value;
        }

        let shape = this.getShape();

        if (shape === "Circle") {
          if (this.circleCenterCoordinate.current != null) {
            if (this.circleCenterCoordinate.current.x.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += "cx=" + this.circleCenterCoordinate.current.x.current.value;
            }

            if (this.circleCenterCoordinate.current.y.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += "cy=" + this.circleCenterCoordinate.current.y.current.value;
            }
          }
        } else if (shape === "Square") {
          if (this.squareCoordinates.current != null) {
            for (let i = 0; i < 2; i++) {
              if (this.squareCoordinates.current.x[i].current != null) {
                if (searchParameters !== "?") { searchParameters += "&"; }

                searchParameters += "sx" + (i + 1) + "=" + this.squareCoordinates.current.x[i].current.value;
              }

              if (this.squareCoordinates.current.y[i].current != null) {
                if (searchParameters !== "?") { searchParameters += "&"; }

                searchParameters += "sy" + (i + 1) + "=" + this.squareCoordinates.current.y[i].current.value;
              }
            }
          }
        }
      }

      if ((mode === "Normal") || (mode === "-")) {
        if ((this.showErrorSpaces.current != null) && (this.battlegroundErrorSpaces.length > 0)) {
          if (searchParameters !== "?") { searchParameters += "&"; }

          searchParameters += "es=" + this.showErrorSpaces.current.checked;
        }

        if ((this.showPasses.current != null) && (this.battlegroundPasses.length > 0)) {
          if (searchParameters !== "?") { searchParameters += "&"; }

          searchParameters += "pe=" + this.showPasses.current.checked;
        }

        if (this.showFixedEndFieldCircles.current != null) {
          if (searchParameters !== "?") { searchParameters += "&"; }

          searchParameters += "fef=" + this.showFixedEndFieldCircles.current.checked;
        }

        if (this.showFixedEndMountainCircles.current != null) {
          if (searchParameters !== "?") { searchParameters += "&"; }

          searchParameters += "fem=" + this.showFixedEndMountainCircles.current.checked;
        }

        if (this.showFixedEndTownCircles.current != null) {
          if (searchParameters !== "?") { searchParameters += "&"; }

          searchParameters += "fet=" + this.showFixedEndTownCircles.current.checked;
        }
      }

      if (mode === "Normal") {
        for (let i = 0; i < 9; i++) {
          if (this.phases[i].current != null) {
            const phaseName = "p" + (i + 1);

            if (this.phases[i].current.delay.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += phaseName + "de=" + this.phases[i].current.delay.current.value;
            }

            if (this.phases[i].current.wait.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += phaseName + "w=" + this.phases[i].current.wait.current.value;
            }

            if (this.phases[i].current.move.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += phaseName + "m=" + this.phases[i].current.move.current.value;
            }

            if (this.phases[i].current.dps.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += phaseName + "da=" + this.phases[i].current.dps.current.value;
            }

            if (this.phases[i].current.shrink.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += phaseName + "sh=" + this.phases[i].current.shrink.current.value;
            }

            if (this.phases[i].current.spread.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += phaseName + "sp=" + this.phases[i].current.spread.current.value;
            }

            if (this.phases[i].current.landRatio.current != null) {
              if (searchParameters !== "?") { searchParameters += "&"; }

              searchParameters += phaseName + "lr=" + this.phases[i].current.landRatio.current.value;
            }
          }
        }
      }

      window.history.replaceState(null, "", searchParameters);
    }
  }

  setSearchParametersTimeout(blueZoneElucidator) {
    blueZoneElucidator.setSearchParameters();
  }

  toggleErrorSpacesVisibility = (event) => {
    this.setSearchParameters();

    this.drawCanvas(false);
  }

  toggleFixedEndCirclesVisibility = (event) => {
    this.setSearchParameters();

    this.drawCanvas(false);
  }

  togglePassesVisibility = (event) => {
    this.setSearchParameters();

    this.drawCanvas(false);
  }

  touchEnd = (event) => {
    switch (event.touches.length) {
    case 0:
      if (this.touchStartSquareCorner) {
        event.stopPropagation();
        event.preventDefault();

        if (this.squareCoordinates.current != null) {
          if ((this.squareCoordinates.current.x[0].current != null) && (this.squareCoordinates.current.y[0].current != null) &&
              (this.squareCoordinates.current.x[1].current != null) && (this.squareCoordinates.current.y[1].current != null)) {
            if (this.touchCoordinateX < this.touchStartCoordinateX) {
              this.squareCoordinates.current.x[0].current.value = this.touchCoordinateX;
              this.squareCoordinates.current.x[1].current.value = this.touchStartCoordinateX;
            } else {
              this.squareCoordinates.current.x[0].current.value = this.touchStartCoordinateX;
              this.squareCoordinates.current.x[1].current.value = this.touchCoordinateX;
            }

            if (this.touchCoordinateY < this.touchStartCoordinateY) {
              this.squareCoordinates.current.y[0].current.value = this.touchCoordinateY;
              this.squareCoordinates.current.y[1].current.value = this.touchStartCoordinateY;
            } else {
              this.squareCoordinates.current.y[0].current.value = this.touchStartCoordinateY;
              this.squareCoordinates.current.y[1].current.value = this.touchCoordinateY;
            }
          }
        }

        this.touchStartSquareCorner = false;

        if (this.mounted) { this.drawCanvas(true); }
      }

      this.setSearchParameters();

      break;
    case 1:
      event.stopPropagation();
      event.preventDefault();

      this.setSearchParameters();

      this.touchInit(event);

      break;
    default:
      break;
    }

    this.touchRecentlyEnded = true;

    window.clearTimeout (this.touchRecentlyEndedTimeoutID);

    this.touchRecentlyEndedTimeoutID = window.setTimeout(this.clearTouchRecentlyEndedTimeout, 800, this);
  }

  touchInit(event) {
    this.touchStartMinimapTouchX = event.touches[0].pageX - this.minimapCanvas.current.offsetLeft;
    this.touchStartMinimapTouchY = event.touches[0].pageY - this.minimapCanvas.current.offsetTop;

    this.touchStartMinimapOffsetX = this.minimapOffsetX;
    this.touchStartMinimapOffsetY = this.minimapOffsetY;

    this.touchStartSquareCorner = false;

    if (this.shape.current.value === "Square") {
      if (this.squareCoordinates.current != null) {
        if ((this.squareCoordinates.current.x[0].current != null) && (this.squareCoordinates.current.y[0].current != null) &&
            (this.squareCoordinates.current.x[1].current != null) && (this.squareCoordinates.current.y[1].current != null)) {
          const grabDistance = 0.03 / this.minimapScale;

          const touchStartCoordinateX = (this.touchStartMinimapTouchX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
          const touchStartCoordinateY = (this.touchStartMinimapTouchY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);

          let minimumTouchDistance;

          for (let i = 0; i < 2; i++) {
            for (let j = 0; j < 2; j++) {
              const touchDistance = Math.sqrt((Number(this.squareCoordinates.current.x[i].current.value) - touchStartCoordinateX) ** 2 +
                                              (Number(this.squareCoordinates.current.y[j].current.value) - touchStartCoordinateY) ** 2);

              if (touchDistance <= grabDistance) {
                if (this.touchStartSquareCorner) {
                  if (touchDistance < minimumTouchDistance) {
                    this.touchStartCoordinateX = Number(this.squareCoordinates.current.x[1-i].current.value).toFixed(2);
                    this.touchStartCoordinateY = Number(this.squareCoordinates.current.y[1-j].current.value).toFixed(2);

                    minimumTouchDistance = touchDistance;
                  }
                } else {
                  this.touchStartSquareCorner = true;

                  this.touchStartCoordinateX = Number(this.squareCoordinates.current.x[1-i].current.value).toFixed(2);
                  this.touchStartCoordinateY = Number(this.squareCoordinates.current.y[1-j].current.value).toFixed(2);

                  minimumTouchDistance = touchDistance;
                }
              }
            }
          }
        }
      }
    }
  }

  touchMove = (event) => {
    if (this.minimapCanvas.current == null) { return; }

    switch (event.touches.length) {
    case 1:
      event.stopPropagation();
      event.preventDefault();

      const minimapTouchX = event.touches[0].pageX - this.minimapCanvas.current.offsetLeft;
      const minimapTouchY = event.touches[0].pageY - this.minimapCanvas.current.offsetTop;

      if (this.touchStartSquareCorner) {
        this.touchCoordinateX = (minimapTouchX - this.minimapOffsetX) / (this.minimapScale * this.minimapCanvas.current.width);
        this.touchCoordinateX = this.touchCoordinateX.toFixed(2);
        this.touchCoordinateY = (minimapTouchY - this.minimapOffsetY) / (this.minimapScale * this.minimapCanvas.current.height);
        this.touchCoordinateY = this.touchCoordinateY.toFixed(2);
      } else {
        this.minimapOffsetX = this.touchStartMinimapOffsetX + minimapTouchX - this.touchStartMinimapTouchX;
        this.minimapOffsetY = this.touchStartMinimapOffsetY + minimapTouchY - this.touchStartMinimapTouchY;

        this.restrictMinimapOffset();
      }

      if (this.mounted) { this.drawCanvas(false); }

      break;
    case 2:
      event.stopPropagation();
      event.preventDefault();

      const pinchMinimapCenterX = 0.5 * (event.touches[0].pageX + event.touches[1].pageX) - this.minimapCanvas.current.offsetLeft;
      const pinchMinimapCenterY = 0.5 * (event.touches[0].pageY + event.touches[1].pageY) - this.minimapCanvas.current.offsetTop;

      const pinchMinimapDistance = Math.sqrt((event.touches[0].pageX - event.touches[1].pageX) ** 2 +
                                             (event.touches[0].pageY - event.touches[1].pageY) ** 2);

      const previousMinimapScale = this.minimapScale;

      this.minimapScale = pinchMinimapDistance / this.pinchStartMinimapDistance * this.pinchStartMinimapScale;

      this.restrictMinimapScale();

      this.minimapOffsetX += (pinchMinimapCenterX - this.minimapOffsetX) * (previousMinimapScale - this.minimapScale) / previousMinimapScale;
      this.minimapOffsetY += (pinchMinimapCenterY - this.minimapOffsetY) * (previousMinimapScale - this.minimapScale) / previousMinimapScale;

      this.minimapOffsetX += pinchMinimapCenterX - this.pinchPreviousMinimapCenterX;
      this.minimapOffsetY += pinchMinimapCenterY - this.pinchPreviousMinimapCenterY;

      this.restrictMinimapOffset();

      if (this.mounted) { this.drawCanvas(false); }

      this.pinchPreviousMinimapCenterX = pinchMinimapCenterX;
      this.pinchPreviousMinimapCenterY = pinchMinimapCenterY;

      break;
    default:
      break;
    }

    this.touchRecentlyMoved = true;

    window.clearTimeout (this.touchRecentlyMovedTimeoutID);

    this.touchRecentlyMovedTimeoutID = window.setTimeout(this.clearTouchRecentlyMovedTimeout, 800, this);
  }

  touchStart = (event) => {
    if (this.minimapCanvas.current == null) { return; }

    switch (event.touches.length) {
    case 1:
      event.stopPropagation();
      event.preventDefault();

      this.touchInit(event);

      break;
    case 2:
      event.stopPropagation();
      event.preventDefault();

      this.pinchPreviousMinimapCenterX = 0.5 * (event.touches[0].pageX + event.touches[1].pageX) - this.minimapCanvas.current.offsetLeft;
      this.pinchPreviousMinimapCenterY = 0.5 * (event.touches[0].pageY + event.touches[1].pageY) - this.minimapCanvas.current.offsetTop;

      this.pinchStartMinimapDistance = Math.sqrt((event.touches[0].pageX - event.touches[1].pageX) ** 2 +
                                                 (event.touches[0].pageY - event.touches[1].pageY) ** 2);

      this.pinchStartMinimapScale = this.minimapScale;

      break;
    default:
      break;
    }
  }

  updateAnnotations = (event) => {
    if (this.battleground.current == null) { return; }

    if (this.shape.current == null) { return; }

    for (let i = 0; i < 9; i++) {
      if (this.phases[i].current == null) { return; }
      if (this.phases[i].current.move.current == null) { return; }
      if (this.phases[i].current.shrink.current == null) { return; }
    }

    if (this.shape.current.value === "Circle") {
      let radius = this.battlegrounds[this.battleground.current.value].radius;

      for (let i = 0; i < 9; i++) {
        if (this.phases[i].current.shrink.current.value === "") {
          this.phases[i].current.size = "";

          this.phases[i].current.radius = radius;

          continue;
        }

        radius *= Number(this.phases[i].current.shrink.current.value);

        this.phases[i].current.radius = radius;

        this.phases[i].current.diameter = 2 * radius;

        this.phases[i].current.diameter = this.phases[i].current.diameter.toPrecision(3);

        if (this.phases[i].current.diameter > 1000.0) {
          this.phases[i].current.diameter = this.phases[i].current.diameter / 1000.0 + " km";
        } else {
          this.phases[i].current.diameter = this.phases[i].current.diameter + " m";
        }

        this.phases[i].current.size = this.phases[i].current.diameter;
      }

      for (let i = 0; i < 9; i++) {
        if (this.phases[i].current.move.current.value === "") {
          this.phases[i].current.maximumSpeed = "";

          continue;
        } else if (Number(this.phases[i].current.move.current.value) === 0.0) {
          this.phases[i].current.maximumSpeed = "0.00 m/s";

          continue;
        }

        if (i === 0) {
          this.phases[i].current.maximumSpeed = 2.0 * (this.battlegrounds[this.battleground.current.value].radius - this.phases[i].current.radius) / Number(this.phases[i].current.move.current.value);
        } else {
          this.phases[i].current.maximumSpeed = 2.0 * (this.phases[i-1].current.radius - this.phases[i].current.radius) / Number(this.phases[i].current.move.current.value);
        }

        this.phases[i].current.maximumSpeed = this.phases[i].current.maximumSpeed.toPrecision(3) + " m/s";
      }
    } else if (this.shape.current.value === "Square") {
      if (this.squareCoordinates.current == null) { return; }
      if ((this.squareCoordinates.current.x[0].current == null) || (this.squareCoordinates.current.x[1].current == null) ||
          (this.squareCoordinates.current.y[0].current == null) || (this.squareCoordinates.current.y[1].current == null)) {
        return;
      }

      let initialWidth = 2 * this.battlegrounds[this.battleground.current.value].radius;
      let initialHeight = 2 * this.battlegrounds[this.battleground.current.value].radius;

      let width = this.battlegrounds[this.battleground.current.value].width;
      let height = this.battlegrounds[this.battleground.current.value].height;

      let mapX = [0.5 * width, 0.5 * width];
      let mapY = [0.5 * height, 0.5 * height];

      if (width > height) {
        initialWidth *= width / height;
      } else {
        initialHeight *= height / width;
      }

      mapX[0] -= 0.5 * initialWidth;
      mapX[1] += 0.5 * initialWidth;
      mapY[0] -= 0.5 * initialHeight;
      mapY[1] += 0.5 * initialHeight;

      let x;

      if (Number(this.squareCoordinates.current.x[0].current.value) < Number(this.squareCoordinates.current.x[1].current.value)) {
        x = [width * Number(this.squareCoordinates.current.x[0].current.value),
             width * Number(this.squareCoordinates.current.x[1].current.value)];
      } else {
        x = [width * Number(this.squareCoordinates.current.x[1].current.value),
             width * Number(this.squareCoordinates.current.x[0].current.value)];
      }

      let y;

      if (Number(this.squareCoordinates.current.y[0].current.value) < Number(this.squareCoordinates.current.y[1].current.value)) {
        y = [height * Number(this.squareCoordinates.current.y[0].current.value),
             height * Number(this.squareCoordinates.current.y[1].current.value)];
      } else {
        y = [height * Number(this.squareCoordinates.current.y[1].current.value),
             height * Number(this.squareCoordinates.current.y[0].current.value)];
      }

      let maxDistance = 0;

      for (let i = 0; i < 2; i++) {
        for (let j = 0; j < 2; j++) {
          const distance = Math.sqrt((x[i] - mapX[i]) ** 2 + (y[j] - mapY[j]) ** 2);

          if (distance > maxDistance) { maxDistance = distance; }
        }
      }

      width *= Math.abs(Number(this.squareCoordinates.current.x[1].current.value) - Number(this.squareCoordinates.current.x[0].current.value));
      height *= Math.abs(Number(this.squareCoordinates.current.y[1].current.value) - Number(this.squareCoordinates.current.y[0].current.value));

      let radius = 0.5 * Math.sqrt(width ** 2 + height ** 2);

      for (let i = 0; i < 9; i++) {
        if (i > 0) {
          if (this.phases[i].current.shrink.current.value === "") {
            this.phases[i].current.size = "";

            this.phases[i].current.width = width;
            this.phases[i].current.height = height;

            this.phases[i].current.radius = radius;

            continue;
          }

          width *= Number(this.phases[i].current.shrink.current.value);
          height *= Number(this.phases[i].current.shrink.current.value);

          radius *= Number(this.phases[i].current.shrink.current.value);
        }

        this.phases[i].current.radius = radius;

        this.phases[i].current.width = width;
        this.phases[i].current.width = this.phases[i].current.width.toPrecision(3);

        if (this.phases[i].current.width > 1000) {
          this.phases[i].current.width = this.phases[i].current.width / 1000.0 + " km";
        } else {
          this.phases[i].current.width = this.phases[i].current.width + " m";
        }

        this.phases[i].current.height = height;
        this.phases[i].current.height = this.phases[i].current.height.toPrecision(3);

        if (this.phases[i].current.height > 1000) {
          this.phases[i].current.height = this.phases[i].current.height / 1000.0 + " km";
        } else {
          this.phases[i].current.height = this.phases[i].current.height + " m";
        }

        this.phases[i].current.size = this.phases[i].current.width + "\n×\n" + this.phases[i].current.height;
      }

      for (let i = 0; i < 9; i++) {
        if (this.phases[i].current.move.current.value === "") {
          this.phases[i].current.maximumSpeed = "";

          continue;
        } else if (Number(this.phases[i].current.move.current.value) === 0.0) {
          this.phases[i].current.maximumSpeed = "0.00 m/s";

          continue;
        }

        if (i === 0) {
          this.phases[i].current.maximumSpeed = maxDistance / Number(this.phases[i].current.move.current.value);
        } else {
          this.phases[i].current.maximumSpeed = 2.0 * (this.phases[i-1].current.radius - this.phases[i].current.radius) / Number(this.phases[i].current.move.current.value);
        }

        this.phases[i].current.maximumSpeed = this.phases[i].current.maximumSpeed.toPrecision(3) + " m/s";
      }
    }

    this.updateTotalRoundTimeString();

    this.setState({});
  }

  updateBlueZoneElucidator = (event) => {
    this.drawCanvas(false);

    if (this.getMode() === "Normal") { this.updateAnnotations(); }
  }

  updateHighResolutionMinimapCanvas() {
    if (this.userAgentIsSafari) {
      if (this.battleground.current == null) { return; }

      const minimap = this.battlegrounds[this.battleground.current.value].minimap;

      if (minimap.allRandomElementsLoaded) { minimap.shuffleRandomDistribution(); }

      return;
    }

    if (this.highResolutionMinimapCanvas.current == null) { return; }

    if (this.battleground.current == null) { return; }

    const minimap = this.battlegrounds[this.battleground.current.value].minimap;

    this.highResolutionMinimapCanvas.current.width = minimap.highResolutionDimensions[0];
    this.highResolutionMinimapCanvas.current.height = minimap.highResolutionDimensions[1];

    const highResolutionContext = this.highResolutionMinimapCanvas.current.getContext('2d', { alpha: true });

    highResolutionContext.clearRect(0, 0, minimap.highResolutionDimensions[0], minimap.highResolutionDimensions[1]);

    const highResolutionImageWidth = Math.floor(minimap.divisionFractions[0] * minimap.highResolutionDimensions[0]);
    const highResolutionImageHeight = Math.floor(minimap.divisionFractions[1] * minimap.highResolutionDimensions[1]);

    for (let i = 0; i < minimap.highResolutionImages.length; i++) {
      if (minimap.highResolutionImages[i].loaded) {
        const highResolutionImageOffsetX = minimap.highResolutionImages[i].indexes[0] * highResolutionImageWidth;
        const highResolutionImageOffsetY = minimap.highResolutionImages[i].indexes[1] * highResolutionImageHeight;

        highResolutionContext.drawImage(minimap.highResolutionImages[i].image, highResolutionImageOffsetX, highResolutionImageOffsetY);
      }
    }

    if (minimap.allRandomElementsLoaded) {
      minimap.shuffleRandomDistribution();

      for (let i = 0; i < minimap.randomElements.length; i++) {
        const terrainOffset = [Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[0] + minimap.randomElements[i].terrain.offset[0]),
                               Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[1] + minimap.randomElements[i].terrain.offset[1])];

        highResolutionContext.drawImage(minimap.randomElements[i].terrain.image, terrainOffset[0], terrainOffset[1]);

        const objectOffset = [Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[0] + minimap.randomElements[i].object.offset[0]),
                              Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[1] + minimap.randomElements[i].object.offset[1])];

        highResolutionContext.drawImage(minimap.randomElements[i].object.image, objectOffset[0], objectOffset[1]);

        const labelOffset = [Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[0] + minimap.randomElements[i].label.offset[0]),
                             Math.floor(minimap.randomOverlayLocations[minimap.randomDistribution[i]].coordinate[1] + minimap.randomElements[i].label.offset[1])];

        highResolutionContext.drawImage(minimap.randomElements[i].label.image, labelOffset[0], labelOffset[1]);
      }
    }
  }

  updateMinimapDimensions = (event) => {
    if (this.minimapCanvas.current == null) { return false; }

    const minimumSize = 508;

    let minimapSize = 0;

    if (document.documentElement.clientHeight < (document.documentElement.clientWidth - minimumSize / 2)) {
      minimapSize = document.documentElement.clientHeight;

      if (this.minimapSpacer.current != null) {
        minimapSize -= 4.0 * this.minimapSpacer.current.clientHeight;
      }

      if (this.settings.current != null) {
        let maxWidth = document.documentElement.clientWidth - this.settings.current.offsetLeft - this.settings.current.clientWidth;

        if (this.minimapSpacer.current != null) {
          maxWidth -= 2.5 * this.minimapSpacer.current.clientHeight;
        }

        if (minimapSize > maxWidth) { minimapSize = maxWidth; }
      }
    }

    if (minimapSize < minimumSize) {
      minimapSize = document.documentElement.clientWidth;

      if (this.minimapSpacer.current != null) {
        minimapSize -= 2.0 * this.minimapSpacer.current.clientHeight;
      }
    }

    if (minimapSize < minimumSize) { minimapSize = minimumSize; }

    minimapSize = Math.trunc(minimapSize);

    if ((this.minimapCanvas.current.width === minimapSize) && (this.minimapCanvas.current.height === minimapSize)) {
      return false;
    }

    const mx = (-this.minimapOffsetX + 0.5 * this.minimapCanvas.current.width) / (this.minimapScale * this.minimapCanvas.current.width);
    this.minimapOffsetX = -mx * this.minimapScale * minimapSize + 0.5 * minimapSize;
    const my = (-this.minimapOffsetY + 0.5 * this.minimapCanvas.current.height) / (this.minimapScale * this.minimapCanvas.current.height);
    this.minimapOffsetY = -my * this.minimapScale * minimapSize + 0.5 * minimapSize;

    this.minimapCanvas.current.width = minimapSize;
    this.minimapCanvas.current.height = minimapSize;

    this.updateMinimapImageLoadingPriorities();

    this.drawCanvas(false);

    return true;
  }

  updateMinimapDimensionsTimeout(blueZoneElucidator) {
    blueZoneElucidator.updateMinimapDimensions();
  }

  updateMinimapImageLoadingPriorities() {
    if (!this.battlegrounds[this.battleground.current.value].minimap.loadingImages) { return; }

    if (this.battleground.current == null) { return; }
    if (this.minimapCanvas.current == null) { return; }

    let minimap = this.battlegrounds[this.battleground.current.value].minimap;

    const highResolutionImageWidth = this.minimapScale * this.minimapCanvas.current.width * minimap.divisionFractions[0];
    const highResolutionImageHeight = this.minimapScale * this.minimapCanvas.current.height * minimap.divisionFractions[1];

    let maxPriority = 0;

    for (let i = 0; i < minimap.highResolutionImages.length; i++) {
      if (!minimap.highResolutionImages[i].loading && !minimap.highResolutionImages[i].loaded) {
        let priority = 0;

        const highResolutionImageOffsetX = this.minimapOffsetX + minimap.highResolutionImages[i].indexes[0] * highResolutionImageWidth;
        const highResolutionImageOffsetY = this.minimapOffsetY + minimap.highResolutionImages[i].indexes[1] * highResolutionImageHeight;

        if ((highResolutionImageOffsetX + highResolutionImageWidth > 0) && (highResolutionImageOffsetX <= this.minimapCanvas.current.width) &&
            (highResolutionImageOffsetY + highResolutionImageHeight > 0) && (highResolutionImageOffsetY <= this.minimapCanvas.current.height)) {
          priority++;
        }

        if (this.minimapMouseHover &&
            (highResolutionImageOffsetX < this.mouseMinimapX) && ((highResolutionImageOffsetX + highResolutionImageWidth) > this.mouseMinimapX) &&
            (highResolutionImageOffsetY < this.mouseMinimapY) && ((highResolutionImageOffsetY + highResolutionImageHeight) > this.mouseMinimapY)) {
          priority++;
        }

        this.battlegrounds[this.battleground.current.value].minimap.highResolutionImages[i].priority = priority;

        maxPriority = Math.max(priority, maxPriority);
      }
    }

    minimap.maxPriority = maxPriority;
  }

  updateTeamDeathmatchesVisibility = (event) => {
    this.setSearchParameters();

    this.drawCanvas(false);
  }

  updateTotalRoundTimeString = (event) => {
    for (let i = 0; i < 9; i++) {
      if (this.phases[i].current == null) { return; }
      if (this.phases[i].current.delay.current == null) { return; }
      if (this.phases[i].current.move.current == null) { return; }
      if (this.phases[i].current.wait.current == null) { return; }
    }

    let totalRoundTimeSeconds = 0;

    for (let i = 0; i < 9; i++) {
      totalRoundTimeSeconds += Number(this.phases[i].current.delay.current.value) + Number(this.phases[i].current.wait.current.value) + Number(this.phases[i].current.move.current.value);
    }

    const totalRoundTimeMinutes = Math.floor(totalRoundTimeSeconds / 60);

    totalRoundTimeSeconds -= 60 * totalRoundTimeMinutes;

    const previousTotalRoundTimeString = this.totalRoundTimeString;

    this.totalRoundTimeString = totalRoundTimeMinutes + "m" + totalRoundTimeSeconds + "s";

    if (this.totalRoundTimeString !== previousTotalRoundTimeString) { this.setState({}); }

    this.setSearchParameters();
  }

  render() {
    let mode = this.getMode();

    let thisMaps = "";
    if (this.battleground.current != null) {
      thisMaps = this.battleground.current[this.battleground.current.selectedIndex].text + "'s ";
    }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const loadingNotificationDivDisplay = this.battleground.current != null ? this.battlegrounds[this.battleground.current.value].minimap.anyHighResolutionImageLoading ? 'inline' : 'none' : 'none';
    const loadingNotificationDiv = <div className="loadingNotification" style={{ display: loadingNotificationDivDisplay }}>Loading High Resolution Map…</div>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const search = window.location.search;

    const lbzeHREF = "/" + search;
    const loafbzeHREF = "/oaf/" + search;
    const lmtsHREF = "/mts/" + search;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let showErrorSpacesCheckbox = <div className="checkbox" style={{ visibility: (((mode === "Normal") || (mode === "-")) && (this.battlegroundErrorSpaces.length > 0)) ? 'visible' : 'hidden' }}>
                                    <label className="checkbox"><input type="checkbox" onChange={this.toggleErrorSpacesVisibility} ref={this.showErrorSpaces} />Show Error Spaces</label>
                                  </div>;

    let showPassesCheckbox = <div className="checkbox">
                               <label className="checkbox"><input type="checkbox" onChange={this.togglePassesVisibility} ref={this.showPasses} />Show Prohibited End Locations</label>
                             </div>;

    let showFixedEndFieldCirclesCheckbox = <div className="checkbox" style={{ visibility: ((mode === "Normal") || (mode === "-")) ? 'visible' : 'hidden' }}>
                                             <label className="checkbox"><input type="checkbox" onChange={this.toggleFixedEndCirclesVisibility} ref={this.showFixedEndFieldCircles} />Field</label>
                                           </div>;

    let showFixedEndMountainCirclesCheckbox = <div className="checkbox" style={{ visibility: ((mode === "Normal") || (mode === "-")) ? 'visible' : 'hidden' }}>
                                                <label className="checkbox"><input type="checkbox" onChange={this.toggleFixedEndCirclesVisibility} ref={this.showFixedEndMountainCircles} />Mountain</label>
                                              </div>;

    let showFixedEndTownCirclesCheckbox = <div className="checkbox" style={{ visibility: ((mode === "Normal") || (mode === "-")) ? 'visible' : 'hidden' }}>
                                            <label className="checkbox"><input type="checkbox" onChange={this.toggleFixedEndCirclesVisibility} ref={this.showFixedEndTownCircles} />Town</label>
                                          </div>;

    let phasesValuesOptions = [];
    if (this.battleground.current != null) {
      const battleground = this.battlegrounds[this.battleground.current.value];

      if (battleground.phasesDefaultValues != null) {
        phasesValuesOptions.push(<option key="-1" value="Default">Default Values</option>);
      }

      for (let i = 0; i < battleground.specialNormalModes.length; i++) {
        phasesValuesOptions.push(<option key={i} value={battleground.specialNormalModes[i].name}>{battleground.specialNormalModes[i].name} Values</option>);
      }
    }

    let setPhaseValuesForAllPhasesButton = <button style={{ display: (mode === "Normal") ? 'inline' : 'none', visibility: (phasesValuesOptions.length > 0) ? 'visible' : 'hidden' }} onClick={this.setPhaseValuesForAllPhases}>Set Values for All Phases to {thisMaps}</button>;

    let phasesValuesSelect = <select style={{ display: ((mode === "Normal") && (phasesValuesOptions.length > 0)) ? 'inline' : 'none' }} defaultValue="Default" ref={this.phasesValues}>
                               {phasesValuesOptions}
                             </select>;

    let shapeSelect = <select style={{ display : (mode === "Normal") ? 'inline' : 'none' }} defaultValue="Circle" onChange={this.selectShape} ref={this.shape}>
                        <option value="Circle">Circle</option>
                        <option value="Square">Square</option>
                      </select>;

    let shape = this.getShape();

    const circleCenterCoordinateDisplay = ((mode === "Normal") && (shape === "Circle")) ? 'inline' : 'none';
    const squareCoordinatesDisplay = ((mode === "Normal") && (shape === "Square")) ? 'inline' : 'none';

    let coordinatesTables = [];
    coordinatesTables.push(<CircleCenterCoordinate key="0" display={circleCenterCoordinateDisplay} onChange={this.updateBlueZoneElucidator} ref={this.circleCenterCoordinate} />);
    coordinatesTables.push(<SquareCoordinates key="1" display={squareCoordinatesDisplay} onChange={this.updateBlueZoneElucidator} ref={this.squareCoordinates} />);

    let shrinkUnits = "\n";
    let zoneSize = "length";
    let zoneShape = "zone";

    if (this.shape.current != null) {
      if (this.shape.current.value === "Circle") {
        shrinkUnits = "\n(dia)";
        zoneSize = "diameter";
      } else if (this.shape.current.value === "Square") {
        shrinkUnits = "\n(w×h)";
        zoneSize = "width/height";
      }

      zoneShape = this.shape.current.value.toLowerCase();
    }

    const delayTip = "Time (in seconds) between end of previous phase and display of this phase's " + zoneShape + ".  If this is set to 0 for Phase 1 the initial " + zoneShape + " will be visible from the plane.";
    const waitTip = "Time (in seconds) between display of this phase's " + zoneShape + " and start of the " + zoneShape + " closing from the previous phase's " + zoneShape + " to this phase's " + zoneShape + ".";
    const moveTip = "Time (in seconds) it takes for the " + zoneShape + " to close from the previous phase's " + zoneShape + " to this phase's " + zoneShape + ".  If this is set to 0 then the match will never advance to the next phase.";
    const dpsTip = "Minimum damage per second dealt to players in the blue zone.  More damage is dealt to players the farther they are from the zone wall.";
    const shrinkTip = "Scaling factor between the " + zoneSize + " of the previous " + zoneShape + " and the current " + zoneShape + " (e.g. a value if 0.1 means this phase's " + zoneShape + " will have a " + zoneSize + " 10% the size of the previous zone's).";
    const spreadTip = "Controls allowed deviation between center of previous phase and the center of this phase.  The higher the spread value, the less deviation (with a setting of 10 ensuring shared centers).";
    const landRatioTip = "Controls how strongly " + zoneShape + "s that contain more land (instead of water) are favored when choosing the next " + zoneShape + " center.  If this is set to 1 then the " + zoneShape + " will have as little water as possible.";

    const phasesAnnotationsTableRow = <tr className="oneLineAnnotation">
                                        <td></td>
                                        <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Delay<span className="info" data-tip={delayTip} data-event='click focus'>*</span></td>
                                        <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Wait<span className="info" data-tip={waitTip} data-event='click focus'>*</span></td>
                                        <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Move<span className="info" data-tip={moveTip} data-event='click focus'>*</span><br/>(max spd)</td>
                                        <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>DPS<span className="info" data-tip={dpsTip} data-event='click focus'>*</span></td>
                                        <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Shrink<span className="info" data-tip={shrinkTip} data-event='click focus'>*</span>{shrinkUnits}</td>
                                        <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Spread<span className="info" data-tip={spreadTip} data-event='click focus'>*</span></td>
                                        <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Land Ratio<span className="info" data-tip={landRatioTip} data-event='click focus'>*</span></td>
                                      </tr>;

    let phasesTableRows = [];
    for (let i = 0; i < 9; i++) {
      phasesTableRows.push(<Phase key={i}
                                  number={i+1}
                                  delayOnChange={this.updateTotalRoundTimeString}
                                  waitOnChange={this.updateTotalRoundTimeString}
                                  moveOnChange={this.updateAnnotations}
                                  dpsOnChange={this.setSearchParameters}
                                  shrinkOnChange={this.updateBlueZoneElucidator}
                                  spreadOnChange={this.setSearchParameters}
                                  landRatioOnChange={this.setSearchParameters}
                                  ref={this.phases[i]} />);
    }

    const phasesTable = <table className="phases" style={{ display: (mode === "Normal") ? 'block' : 'none' }}>
                          <tbody>
                            <tr className="oneLineAnnotation">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {phasesAnnotationsTableRow}
                            {phasesTableRows}
                            <tr className="oneLineAnnotation">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>;

    const totalRoundTimeDiv = <div className="information" style={{ display: (mode === "Normal") ? 'table' : 'none' }}>Total Round Time: {this.totalRoundTimeString}</div>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let teamDeathmatchButtons = [];
    if (mode === "TDM") {
      for (let i = 0; i < this.teamDeathmatches.length; i++) {
        if (this.minimapCanvas.current != null) {
          let minimapOffsetX = -this.teamDeathmatches[i].centroid[0] * this.teamDeathmatches[i].minimapScale * this.minimapCanvas.current.width + 0.5 * this.minimapCanvas.current.width;
          let minimapOffsetY = -this.teamDeathmatches[i].centroid[1] * this.teamDeathmatches[i].minimapScale * this.minimapCanvas.current.height + 0.5 * this.minimapCanvas.current.height;

          teamDeathmatchButtons.push(<button key={i} onClick={this.handleSetMinimapViewportClick.bind(this, this.teamDeathmatches[i].battleground, this.teamDeathmatches[i].minimapScale, minimapOffsetX, minimapOffsetY)}>{this.teamDeathmatches[i].label}</button>);
        } else {
          teamDeathmatchButtons.push(<div key={i} className="information"><a href={this.teamDeathmatches[i].href}>this.teamDeathmatches[i].label</a></div>);
        }
      }
    }

    let teamDeathmatchesDiv = <div className="informationGrid" style={{ display: (mode === "TDM") ? 'inline-grid' : 'none' }}>
                                {teamDeathmatchButtons}
                              </div>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const intenseBattleRoyalePhasesAnnotationsTableRow = <tr className="oneLineAnnotation">
                                                           <td></td>
                                                           <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Delay<span className="info" data-tip={delayTip} data-event='click focus'>*</span></td>
                                                           <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Wait<span className="info" data-tip={waitTip} data-event='click focus'>*</span></td>
                                                           <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Move<span className="info" data-tip={moveTip} data-event='click focus'>*</span><br/>(max spd)</td>
                                                           <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>DPS<span className="info" data-tip={dpsTip} data-event='click focus'>*</span></td>
                                                           <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Shrink<span className="info" data-tip={shrinkTip} data-event='click focus'>*</span>{shrinkUnits}</td>
                                                           <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Spread<span className="info" data-tip={spreadTip} data-event='click focus'>*</span></td>
                                                           <td className="annotation"><span className="spacer" style={{ fontSize: "1.4em" }}>*</span>Land Ratio<span className="info" data-tip={landRatioTip} data-event='click focus'>*</span></td>
                                                         </tr>;

    let intenseBattleRoyalePhasesTableRows = [];
    if ((this.battleground.current != null) && (mode === "IntenseBR") && (this.battlegroundIntenseBattleRoyales.length > 0)) {
      for (let i = 0; i < this.battlegrounds[this.battleground.current.value].specialNormalModes.length; i++) {
        if (this.battlegrounds[this.battleground.current.value].specialNormalModes[i].name === "Intense Battle Royale") {
          const specialNormalMode = this.battlegrounds[this.battleground.current.value].specialNormalModes[i];

          let radius = this.battlegrounds[this.battleground.current.value].width * this.battlegroundIntenseBattleRoyales[0].normalizedRadius;

          for (let j = 0; j < specialNormalMode.phasesDefaultValues.length; j++) {
            const phaseRadius = radius * Number(specialNormalMode.phasesDefaultValues[j].shrink);

            let phaseDiameter = 2 * phaseRadius;

            phaseDiameter = phaseDiameter.toPrecision(3);

            if (phaseDiameter > 1000.0) {
              phaseDiameter = phaseDiameter / 1000.0 + " km";
            } else {
              phaseDiameter = phaseDiameter + " m";
            }

            let phaseMaximumSpeed;

            if (Number(specialNormalMode.phasesDefaultValues[j].move) !== 0.0) {
              phaseMaximumSpeed = 2.0 * (radius - phaseRadius) / Number(specialNormalMode.phasesDefaultValues[j].move);

              phaseMaximumSpeed = phaseMaximumSpeed.toPrecision(3) + " m/s";
            } else {
              phaseMaximumSpeed = "0.00 m/s";
            }

            intenseBattleRoyalePhasesTableRows.push(<IntenseBattleRoyalePhase key={j}
                                                                              number={j+1}
                                                                              delay={specialNormalMode.phasesDefaultValues[j].delay}
                                                                              wait={specialNormalMode.phasesDefaultValues[j].wait}
                                                                              move={specialNormalMode.phasesDefaultValues[j].move}
                                                                              dps={specialNormalMode.phasesDefaultValues[j].dps}
                                                                              shrink={specialNormalMode.phasesDefaultValues[j].shrink}
                                                                              spread={specialNormalMode.phasesDefaultValues[j].spread}
                                                                              landRatio={specialNormalMode.phasesDefaultValues[j].landRatio}
                                                                              size={phaseDiameter}
                                                                              maximumSpeed={phaseMaximumSpeed} />);

            radius = phaseRadius;
          }

          break;
        }
      }
    }

    const intenseBattleRoyalePhasesTable = <table className="phases" style={{ display: (mode === "IntenseBR") && (this.battlegroundIntenseBattleRoyales.length > 0) ? 'block' : 'none' }}>
                                             <tbody>
                                               <tr className="oneLineAnnotation">
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                               </tr>
                                               {intenseBattleRoyalePhasesAnnotationsTableRow}
                                               {intenseBattleRoyalePhasesTableRows}
                                               <tr className="oneLineAnnotation">
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                                 <td></td>
                                               </tr>
                                             </tbody>
                                           </table>;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    let allowedRight = document.documentElement.clientWidth;
    if (this.minimapSpacer.current != null) { allowedRight -= this.minimapSpacer.current.clientWidth; }

    let tooltipDivLeft = this.clientMouseX + 14;

    const tooltipMeasurementDiv = document.getElementById('tooltipMeasurement');

    if ((tooltipDivLeft + tooltipMeasurementDiv.clientWidth) > allowedRight) {
      tooltipDivLeft = allowedRight - tooltipMeasurementDiv.clientWidth;
    }

    const tooltipDiv = <Tooltip display={this.minimapMouseHover ? 'table' : 'none'} visibility="visible" position="fixed" left={tooltipDivLeft} top={this.clientMouseY + 20} coordinate={this.mouseMoveCoordinateX.toFixed(3) + ", " + this.mouseMoveCoordinateY.toFixed(3)} label={this.mouseMoveCoordinatesLabel} />;

    let interactionInformationDivDisplay = 'none';
    let interactionInformation = "";
    if (mode === "Normal") {
      interactionInformationDivDisplay = 'table';
      if (shape === "Circle") {
        if (this.isTouchDevice) {
          interactionInformation = "Tap to Place Circle Center";
        } else {
          interactionInformation = "Shift-Click to Place Circle Center";
        }
      } else if (shape === "Square") {
        if (this.isTouchDevice) {
          interactionInformation = "Tap to Set Square Center;  Drag Any Corner of the Square to Resize";
        } else {
          interactionInformation = "Shift-Drag to Set Square Coordinates";
        }
      }
    }
    let interactionInformationDiv = <div className="interactionInformation" style={{ display: interactionInformationDivDisplay }} ref={this.interactionInformationDiv}>{interactionInformation}</div>

    const passesTip = "Prohibited end locations are used to prevent the final zone from being placed at disfavored coordinates.";
    const fixedEndLocationsTip = "If end circle location is fixed and no fixed end location is available, then the end circle will be fixed to (0.0, 0.0).  When the end location is fixed with square zones, the end circle will be fixed to (X2, Y2).";

    return (
      <React.Fragment>
        <div className="minimap">
          <div className="minimapSpacer" ref={this.minimapSpacer}></div>
          <canvas width="254" height="254" style={{ touchAction: 'none' }} onMouseEnter={this.mouseEnterMinimap} onMouseLeave={this.mouseLeaveMinimap} onMouseMove={this.mouseMoveMinimap} onTouchEnd={this.touchEnd} onTouchMove={this.touchMove} onTouchStart={this.touchStart} onWheel={this.scaleMinimap} ref={this.minimapCanvas} />
          <div>
            {interactionInformationDiv}
            {loadingNotificationDiv}
          </div>
        </div>
        <div className="settings" ref={this.settings}>
          <Menu width="25.0em">
            <img className="menuIcon" src="/shortcut_icon.png" alt="" /><a className="menu-item menuLink" href={lbzeHREF}><span>Lunchmeat's Blue Zone Elucidator</span></a>
            <img className="menuIcon" src="/oaf/shortcut_icon.png" alt="" /><a className="menu-item menuLink" href={loafbzeHREF}><span>Lunchmeat's Once & Future Blue Zone Elucidator</span></a>
            <img className="menuIcon" src="/mts/shortcut_icon.png" alt="" /><a className="menu-item menuLink" href={lmtsHREF}><span>Lunchmeat's Moving Transit Schedule</span></a>
          </Menu>
          <div className="titles">
            <div className="title">Lunchmeat's Blue Zone Elucidator</div>
            <div className="subtitle">for Update 30.1</div>
          </div>
          <div className="credits">
            <figure>
              <a href="https://www.jonmarchione.com/"><img src="/images/lunchmeat.webp" title="Lunchmeat art by Jon Marchione" alt="Lunchmeat in a Tactical Stance with an Assault Rifle" width="140" height="146" /></a>
              <figcaption><br/>LBZE is created by <br/><a href="mailto:andymandias@lunchmeat.zone">andymandias</a><br/> with extensive help from <br/><a href="https://twitter.com/ASJ_sapphics">sapphics</a><br/> who mined all of the game data</figcaption>
            </figure>
          </div>
          <div className="battleground">
            <select onChange={this.selectBattleground} ref={this.battleground}>
              <option value="Erangel" selected>Erangel</option>
              <option value="Miramar">Miramar</option>
              <option value="Sanhok">Sanhok</option>
              <option value="Vikendi">Vikendi</option>
              <option value="Karakin">Karakin</option>
              <option value="Paramo">Paramo</option>
              <option value="Taego">Taego</option>
              <option value="Deston">Deston</option>
              <option value="Haven">Haven</option>
              <option value="CampJackal">Camp Jackal</option>
              <option value="Boardwalk">Boardwalk</option>
              <option value="PillarCompound">Pillar Compound</option>
              <option value="Rondo">Rondo</option>
              <option value="Liana">Liana</option>
            </select>
            <span style={{ visibility: ((mode === "Normal") || (mode === "-")) ? 'visible' : 'hidden' }}>{showPassesCheckbox}<span className="info" style={{ marginLeft: '-0.06em' }} data-tip={passesTip} data-event='click focus'>*</span></span>
            {showErrorSpacesCheckbox}
          </div>
          <div className="fixedEndLocations" style={{ visibility: ((mode === "Normal") || (mode === "-")) ? 'visible' : 'hidden' }}>
            Show Fixed End Locations<span className="info" data-tip={fixedEndLocationsTip} data-event='click focus'>*</span>:&nbsp;
            {showFixedEndFieldCirclesCheckbox}
            {showFixedEndMountainCirclesCheckbox}
            {showFixedEndTownCirclesCheckbox}
          </div>
          <div className="mode">
            <table>
              <tbody>
                <tr className="oneLineAnnotation"><td></td></tr>
                <tr>
                  <td>
                    <div className="select">
                      <select className="mode" defaultValue="Normal" onChange={this.selectMode} ref={this.mode}>
                        <option value="-">────</option>
                        <option value="Normal">Normal Mode</option>
                        <option value="TDM">Team Deathmatch</option>
                        <option value="IntenseBR">Intense Battle Royale</option>
                      </select>
                      {shapeSelect}
                    </div>
                  </td>
                </tr>
                <tr className="oneLineAnnotation"><td></td></tr>
              </tbody>
            </table>
            {coordinatesTables}
          </div>
          <div className="set">
            {setPhaseValuesForAllPhasesButton}
            {phasesValuesSelect}
          </div>
          {phasesTable}
          {intenseBattleRoyalePhasesTable}
          {totalRoundTimeDiv}
          {teamDeathmatchesDiv}
        </div>
        <ReactTooltip className="infoTooltip" place="top" effect="solid" backgroundColor="#535355" textColor="#fefeff" border={true} borderColor="#2d2d2f" offset={{ left : -1 }} globalEventOff="click"/>
        {tooltipDiv}
        <canvas width="254" height="254" className="hidden" ref={this.highResolutionMinimapCanvas} />
      </React.Fragment>
    );
  }
}

// --------------------------------------------------------------------

class CapturePoint {
  constructor(label, coordinate, radius) {
    this.label = label;
    this.coordinate = coordinate;
    this.radius = radius;
  }

  inside(x, y) {
    let distanceSquared = (this.coordinate[0] - x) ** 2 + (this.coordinate[1] - y) ** 2;

    return distanceSquared <= this.radius ** 2;
  }
}

// --------------------------------------------------------------------

class CircleCenterCoordinate extends React.Component {
  constructor(props) {
    super(props);
    this.x = React.createRef();
    this.y = React.createRef();
  }

  render() {
    return (
      <table style={{ display: this.props.display }}>
        <tbody>
          <tr className="oneLineAnnotation">
            <td className="annotation">X</td>
            <td className="annotation">Y</td>
          </tr>
          <tr>
            <td><input type="number" className="geometric" defaultValue="0.5" step="0.01" onChange={this.props.onChange} ref={this.x} /></td>
            <td><input type="number" className="geometric" defaultValue="0.5" step="0.01" onChange={this.props.onChange} ref={this.y} /></td>
          </tr>
          <tr className="oneLineAnnotation"><td></td><td></td></tr>
        </tbody>
      </table>
    );
  }
}

// --------------------------------------------------------------------

class ErrorSpace {
  constructor (battleground, label, overrideType, coordinate, radius) {
    this.battleground = battleground;
    this.label = label;
    if (overrideType === "TypeB") {
      this.battlegroundLike = "Erangel-like";
    } else if (overrideType === "TypeC") {
      this.battlegroundLike = "Miramar-like";
    } else if (overrideType === "TypeD") {
      this.battlegroundLike = "Sanhok-like";
    } else if (overrideType === "TypeE") {
      this.battlegroundLike = "Vikendi-like";
    } else if (overrideType === "TypeF") {
      this.battlegroundLike = "Deston-like";
    }
    this.coordinate = coordinate;
    this.radius = radius;
  }

  inside(x, y) {
    let distanceSquared = (this.coordinate[0] - x) ** 2 + (this.coordinate[1] - y) ** 2;

    return distanceSquared <= this.radius ** 2;
  }
}

// --------------------------------------------------------------------

class FixedEndCircle {
  constructor (battleground, coordinate, radius, percentChance) {
    this.battleground = battleground;
    this.coordinate = coordinate;
    this.radius = radius;
    this.percentChance = percentChance;

    this.path = new Path2D();

    this.path.moveTo(coordinate[0] + radius, coordinate[1]);
    this.path.arc(coordinate[0], coordinate[1], radius, 0, 2 * Math.PI);
  }

  inside(x, y) {
    let distanceSquared = (this.coordinate[0] - x) ** 2 + (this.coordinate[1] - y) ** 2;

    return distanceSquared <= this.radius ** 2;
  }
}

// --------------------------------------------------------------------

class IntenseBattleRoyale {
  constructor(battleground, label, coordinate, normalizedRadius, percentChance) {
    this.battleground = battleground;
    this.label = label;
    this.coordinate = coordinate;
    this.normalizedRadius = normalizedRadius;
    this.percentChance = percentChance;
  }

  inside(x, y) {
    let distanceSquared = (this.coordinate[0] - x) ** 2 + (this.coordinate[1] - y) ** 2;

    return distanceSquared <= this.normalizedRadius ** 2;
  }
}

// --------------------------------------------------------------------

class IntenseBattleRoyalePhase extends React.Component {
  render() {
    return (
      <React.Fragment>
        <tr>
          <td className="header">Phase {this.props.number}</td>
          <td className="phases"><input type="text" step="1" value={this.props.delay} disabled /></td>
          <td className="phases"><input type="text" step="1" value={this.props.wait} disabled /></td>
          <td className="phases"><input type="text" step="1" value={this.props.move} disabled /></td>
          <td className="phases"><input type="text" step="0.1" value={this.props.dps} disabled /></td>
          <td className="phases"><input type="text" className="geometric" step="0.01" value={this.props.shrink} disabled /></td>
          <td className="phases"><input type="text" step="0.01" value={this.props.spread} disabled /></td>
          <td className="phases"><input type="text" step="0.01" value={this.props.landRatio} disabled /></td>
        </tr>
        <tr className="oneLineAnnotation">
          <td></td>
          <td></td>
          <td></td>
          <td className="annotation">{this.props.maximumSpeed}</td>
          <td></td>
          <td className="annotation">{this.props.size}</td>
          <td></td>
          <td></td>
        </tr>
      </React.Fragment>
    );
  }
}

// --------------------------------------------------------------------

class Minimap {
  constructor(imageName, divisions, queueLength, updateHighResolutionMinimapCanvas, drawCanvas, updateMinimapDimensions) {
    this.imageName = imageName;

    this.lowResolutionImage = new MinimapLowResolutionImage();
    this.lowResolutionImage.image.onload = () => { this.imageOnload(this.lowResolutionImage); };

    this.divisions = divisions;

    this.divisionFractions = [1.0 / this.divisions[0], 1.0 / this.divisions[1]];

    let middleIndex = [0.5 * this.divisions[0], 0.5 * this.divisions[1]];

    let maxDistance = middleIndex[0] + middleIndex[1];

    this.highResolutionDimensions = [];

    this.highResolutionImages = [];
    let key = 0;
    for (let distance = 0; distance <= maxDistance; distance++) {
      for (let i = 0; i < middleIndex[0]; i++) {
        for (let j = 0; j < middleIndex[1]; j++) {
          let combinedDistance = (middleIndex[0] - i - 1) + (middleIndex[1] - j - 1);

          if (combinedDistance === distance) {
            this.highResolutionImages.push(new MinimapHighResolutionImage(key, [i, j]));

            key++;

            let ii = 2 * middleIndex[0] - i - 1;

            this.highResolutionImages.push(new MinimapHighResolutionImage(key, [ii, j]));

            key++;

            let jj = 2 * middleIndex[1] - j - 1;

            this.highResolutionImages.push(new MinimapHighResolutionImage(key, [i, jj]));

            key++;

            this.highResolutionImages.push(new MinimapHighResolutionImage(key, [ii, jj]));

            key++;
          }
        }
      }
    }
    for (let i = 0; i < this.highResolutionImages.length; i++) {
      this.highResolutionImages[i].image.onload = () => { this.imageOnload(this.highResolutionImages[i]); };
    }

    this.anyHighResolutionImageLoading = false;
    this.anyHighResolutionImageLoaded = false;
    this.allHighResolutionImagesLoaded = false;

    this.randomElements = [];
    this.randomBaseLocations = [];
    this.randomOverlayLocations = [];
    this.randomDistribution = [];

    this.allRandomElementsLoaded = false;
    this.anyRandomElementLoading = false;

    this.queueLength = queueLength;
    this.maxPriority = 0;

    this.loadingImages = false;

    this.updateHighResolutionMinimapCanvas = updateHighResolutionMinimapCanvas;

    this.drawCanvas = drawCanvas;

    this.updateMinimapDimensions = updateMinimapDimensions;
  }

  loadedImages() {
    return this.lowResolutionImage.loaded || this.allHighResolutionImagesLoaded;
  }

  loadImages() {
    if ((!this.lowResolutionImage.loading) && (!this.lowResolutionImage.loaded)) {
      this.lowResolutionImage.loading = true;
      this.lowResolutionImage.image.src = this.imageName + "_Low_Res.webp";

      this.loadingImages = true;
    } else {
      if (this.anyHighResolutionImageLoaded) { this.updateHighResolutionMinimapCanvas(); }

      let queueLength = this.queueLength;

      if ((queueLength > 0) && !this.allHighResolutionImagesLoaded) {
        priority_loop: for (let priority = this.maxPriority; priority >= 0; priority--) {
          for (let i = 0; i < this.highResolutionImages.length; i++) {
            if (!this.highResolutionImages[i].loading && !this.highResolutionImages[i].loaded && (this.highResolutionImages[i].priority === priority)) {
              this.highResolutionImages[i].loading = true;
              this.highResolutionImages[i].image.src = this.imageName + "_High_Res_" + this.highResolutionImages[i].indexes[0] + "_" + this.highResolutionImages[i].indexes[1] + ".webp";

              queueLength--;

              if (queueLength === 0) { break priority_loop; }
            }
          }
        }
      }

      if ((queueLength > 0) && !this.allRandomElementsLoaded && (this.randomElements.length > 0)) {
        for (let i = 0; i < this.randomElements.length; i++) {
          if (!this.randomElements[i].terrain.loading && !this.randomElements[i].terrain.loaded) {
            this.randomElements[i].terrain.loading = true;
            this.randomElements[i].terrain.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].terrain.name + ".webp";

            this.anyRandomElementLoading = true;

            queueLength--;

            if (queueLength === 0) { break; }
          }

          if (!this.randomElements[i].object.loading && !this.randomElements[i].object.loaded) {
            this.randomElements[i].object.loading = true;
            this.randomElements[i].object.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].object.name + ".webp";

            this.anyRandomElementLoading = true;

            queueLength--;

            if (queueLength === 0) { break; }
          }

          if (!this.randomElements[i].label.loading && !this.randomElements[i].label.loaded) {
            this.randomElements[i].label.loading = true;
            this.randomElements[i].label.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].label.name + ".webp";

            this.anyRandomElementLoading = true;

            queueLength--;

            if (queueLength === 0) { break; }
          }
        }
      }

      if (queueLength < this.queueLength) { this.loadingImages = true; }
    }
  }

  imageOnload(image) {
    image.loaded = true;
    image.loading = false;

    if (!this.loadingImages) { return; }

    if (image.type() === "MinimapLowResolutionImage") {
      this.anyHighResolutionImageLoading = true;

      let queueLength = this.queueLength;

      if (queueLength > 0) {
        priority_loop: for (let priority = this.maxPriority; priority >= 0; priority--) {
          for (let i = 0; i < this.highResolutionImages.length; i++) {
            if (!this.highResolutionImages[i].loading && !this.highResolutionImages[i].loaded && (this.highResolutionImages[i].priority === priority)) {
              this.highResolutionImages[i].loading = true;
              this.highResolutionImages[i].image.src = this.imageName + "_High_Res_" + this.highResolutionImages[i].indexes[0] + "_" + this.highResolutionImages[i].indexes[1] + ".webp";

              queueLength--;

              if (queueLength === 0) { break priority_loop; }
            }
          }
        }
      }
    } else if (image.type() === "MinimapHighResolutionImage") {
      if (!this.anyHighResolutionImageLoaded) {
        this.highResolutionDimensions = [this.divisions[0] * image.image.naturalWidth, this.divisions[1] * image.image.naturalHeight];
      }

      this.anyHighResolutionImageLoaded = true;

      let allHighResolutionImagesLoaded = true;

      for (let i = 0; i < this.highResolutionImages.length; i++) {
        if (!this.highResolutionImages[i].loaded) {
          allHighResolutionImagesLoaded = false;

          break;
        }
      }

      this.allHighResolutionImagesLoaded = allHighResolutionImagesLoaded;

      if (allHighResolutionImagesLoaded) {
        this.anyHighResolutionImageLoading = false;

        if (this.randomElements.length > 0) {
          for (let i = 0; i < this.randomElements.length; i++) {
            if (!this.randomElements[i].terrain.loading && !this.randomElements[i].terrain.loaded) {
              this.randomElements[i].terrain.loading = true;
              this.randomElements[i].terrain.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].terrain.name + ".webp";

              this.anyRandomElementLoading = true;

              break;
            }

            if (!this.randomElements[i].object.loading && !this.randomElements[i].object.loaded) {
              this.randomElements[i].object.loading = true;
              this.randomElements[i].object.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].object.name + ".webp";

              this.anyRandomElementLoading = true;

              break;
            }

            if (!this.randomElements[i].label.loading && !this.randomElements[i].label.loaded) {
              this.randomElements[i].label.loading = true;
              this.randomElements[i].label.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].label.name + ".webp";

              this.anyRandomElementLoading = true;

              break;
            }
          }
        } else {
          this.loadingImages = false;
        }
      } else {
        priority_loop: for (let priority = this.maxPriority; priority >= 0; priority--) {
          for (let i = 0; i < this.highResolutionImages.length; i++) {
            if (!this.highResolutionImages[i].loading && !this.highResolutionImages[i].loaded && (this.highResolutionImages[i].priority === priority)) {
              this.highResolutionImages[i].loading = true;
              this.highResolutionImages[i].image.src = this.imageName + "_High_Res_" + this.highResolutionImages[i].indexes[0] + "_" + this.highResolutionImages[i].indexes[1] + ".webp";

              break priority_loop;
            }
          }
        }
      }

      this.updateHighResolutionMinimapCanvas();
//  } else if (image.type() === "MinimapRandomImage") {
    } else {
      let allRandomElementsLoaded = true;

      for (let i = 0; i < this.randomElements.length; i++) {
        if (!this.randomElements[i].terrain.loaded || !this.randomElements[i].object.loaded || !this.randomElements[i].label.loaded) {
          allRandomElementsLoaded = false;

          break;
        }
      }

      this.allRandomElementsLoaded = allRandomElementsLoaded;

      if (this.allRandomElementsLoaded) {
        this.anyRandomElementLoading = false;
        this.loadingImages = false;

        this.updateHighResolutionMinimapCanvas();
      } else {
        for (let i = 0; i < this.randomElements.length; i++) {
          if (!this.randomElements[i].terrain.loading && !this.randomElements[i].terrain.loaded) {
            this.randomElements[i].terrain.loading = true;
            this.randomElements[i].terrain.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].terrain.name + ".webp";

            return;
          }

          if (!this.randomElements[i].object.loading && !this.randomElements[i].object.loaded) {
            this.randomElements[i].object.loading = true;
            this.randomElements[i].object.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].object.name + ".webp";

            return;
          }

          if (!this.randomElements[i].label.loading && !this.randomElements[i].label.loaded) {
            this.randomElements[i].label.loading = true;
            this.randomElements[i].label.image.src = "/minimaps/Paramo_Random/" + this.randomElements[i].label.name + ".webp";

            return;
          }
        }
      }
    }

    if (!this.updateMinimapDimensions()) {
      this.drawCanvas(true);
    }
  }

  shuffleRandomDistribution() {
    this.randomDistribution = [];
    for (let i = 0; i < this.randomElements.length; i++) { this.randomDistribution.push(i); }

    for (let j = this.randomElements.length; j > 0; j--) {
      const swapIndex = Math.floor(Math.random() * j);
      const swapValue = this.randomDistribution[swapIndex];

      this.randomDistribution[swapIndex] = this.randomDistribution[j-1];
      this.randomDistribution[j-1] = swapValue;
    }
  }
}

// --------------------------------------------------------------------

class MinimapImage {
  constructor() {
    this.image = new Image();
    this.loading = false;
    this.loaded = false;
  }

  type() { return "MinimapImage"; }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class MinimapHighResolutionImage extends MinimapImage {
  constructor(key, indexes) {
    super();
    this.key = key;
    this.indexes = indexes;
    this.priority = 0;
  }

  type() { return "MinimapHighResolutionImage"; }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class MinimapLowResolutionImage extends MinimapImage {
  type() { return "MinimapLowResolutionImage"; }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

class MinimapRandomImage extends MinimapImage {
  constructor(name, offset) {
    super();
    this.name = name;
    this.offset = offset;
  }

  type() { return "MinimapRandomImage"; }
}

// --------------------------------------------------------------------

class MinimapRandomBaseLocation {
  constructor(coordinate) {
    this.coordinate = coordinate;
  }
}

// --------------------------------------------------------------------

class MinimapRandomElement {
  constructor(key, imageOnload, terrainName, terrainOffset, objectName, objectOffset, labelName, labelOffset) {
    this.key = key;

    this.imageOnload = imageOnload;

    this.label = new MinimapRandomImage(labelName, labelOffset);
    this.label.image.onload = () => { this.imageOnload(this.label); };

    this.object = new MinimapRandomImage(objectName, objectOffset);
    this.object.image.onload = () => { this.imageOnload(this.object); };

    this.terrain = new MinimapRandomImage(terrainName, terrainOffset);
    this.terrain.image.onload = () => { this.imageOnload(this.terrain); };
  }
}

// --------------------------------------------------------------------

class MinimapRandomOverlayLocation extends MinimapRandomBaseLocation {
  constructor(key, coordinate) {
    super(coordinate);
    this.key = key;
  }
}

// --------------------------------------------------------------------

class PassArea {
  constructor(battleground) {
    this.battleground = battleground;
  }
}

// --------------------------------------------------------------------

class PassCircle extends PassArea {
  constructor(battleground, coordinate, radius) {
    super(battleground);
    this.coordinate = coordinate;
    this.radius = radius;

    this.path = new Path2D();

    this.path.moveTo(coordinate[0] + radius, coordinate[1]);
    this.path.arc(coordinate[0], coordinate[1], radius, 0, 2 * Math.PI);
  }

  inside(x, y) {
    let distanceSquared = (this.coordinate[0] - x) ** 2 + (this.coordinate[1] - y) ** 2;

    return distanceSquared <= this.radius ** 2;
  }
}

// --------------------------------------------------------------------

class PassPolygon extends PassArea {
  constructor(battleground, coordinates) {
    super(battleground);
    this.coordinates = coordinates;

    this.vectors = [];

    for (let i = 0; i < this.coordinates.length; i++) {
      let j = (i + 1) % this.coordinates.length;

      this.vectors.push([this.coordinates[j][0] - this.coordinates[i][0],
                         this.coordinates[j][1] - this.coordinates[i][1]]);
    }

    this.path = new Path2D();

    this.path.moveTo(coordinates[0][0], coordinates[0][1]);
    for (let i = 1; i < this.coordinates.length; i++) {
      this.path.lineTo(coordinates[i][0], coordinates[i][1]);
    }
    this.path.lineTo(coordinates[0][0], coordinates[0][1]);
  }

  inside(x, y) {
    let numberOfIntersections = 0;

    for (let i = 0; i < this.coordinates.length; i++) {
      if (Math.abs(this.vectors[i][1]) > 0.00001) {
        let vectorCoefficient = (y - this.coordinates[i][1]) / this.vectors[i][1];

        if ((vectorCoefficient >= 0.0) && (vectorCoefficient < 1.0)) {
          let intersectionX = this.coordinates[i][0] + vectorCoefficient * this.vectors[i][0];

          if (x <= intersectionX) { numberOfIntersections++; }
        }
      }
    }

    if ((numberOfIntersections % 2) === 0) {
      return false;
    }

    numberOfIntersections = 0;

    for (let i = 0; i < this.coordinates.length; i++) {
      if (Math.abs(this.vectors[i][0]) > 0.00001) {
        let vectorCoefficient = (x - this.coordinates[i][0]) / this.vectors[i][0];

        if ((vectorCoefficient >= 0.0) && (vectorCoefficient < 1.0)) {
          let intersectionY = this.coordinates[i][1] + vectorCoefficient * this.vectors[i][1];

          if (y <= intersectionY) { numberOfIntersections++; }
        }
      }
    }

    return (numberOfIntersections % 2) === 1;
  }
}

// --------------------------------------------------------------------

class Phase extends React.Component {
  constructor(props) {
    super(props);
    this.delay = React.createRef();
    this.diameter = -1.0;
    this.dps = React.createRef();
    this.height = -1.0;
    this.landRatio = React.createRef();
    this.maximumSpeed = -1.0;
    this.move = React.createRef();
    this.radius = -1.0;
    this.shrink = React.createRef();
    this.size = -1.0;
    this.spread = React.createRef();
    this.wait = React.createRef();
    this.width = -1.0;
  }

  render() {
    return (
      <React.Fragment>
        <tr>
          <td className="header">Phase {this.props.number}</td>
          <td className="phases"><input type="number" step="1" onChange={this.props.delayOnChange} ref={this.delay} /></td>
          <td className="phases"><input type="number" step="1" onChange={this.props.waitOnChange} ref={this.wait} /></td>
          <td className="phases"><input type="number" step="1" onChange={this.props.moveOnChange} ref={this.move} /></td>
          <td className="phases"><input type="number" step="0.1" onChange={this.props.dpsOnChange} ref={this.dps} /></td>
          <td className="phases"><input type="number" className="geometric" step="0.01" onChange={this.props.shrinkOnChange} ref={this.shrink} /></td>
          <td className="phases"><input type="number" step="0.01" onChange={this.props.spreadOnChange} ref={this.spread} /></td>
          <td className="phases"><input type="number" step="0.01" onChange={this.props.landRatioOnChange} ref={this.landRatio} /></td>
        </tr>
        <tr className="threeLineAnnotation">
          <td></td>
          <td></td>
          <td></td>
          <td className="annotation">{this.maximumSpeed}</td>
          <td></td>
          <td className="annotation">{this.size}</td>
          <td></td>
          <td></td>
        </tr>
      </React.Fragment>
    );
  }
}

// --------------------------------------------------------------------

class PhaseDefaultValues {
  constructor(delay, wait, move, dps, shrink, spread, landRatio) {
    this.delay = delay;
    this.wait = wait;
    this.move = move;
    this.dps = dps;
    this.shrink = shrink;
    this.spread = spread;
    this.landRatio = landRatio;
  }
}

// --------------------------------------------------------------------

class SpawnPoint {
  constructor(team, coordinate, radius) {
    this.team = team;
    this.coordinate = coordinate;
    this.radius = radius;
  }

  inside(x, y) {
    let distanceSquared = (this.coordinate[0] - x) ** 2 + (this.coordinate[1] - y) ** 2;

    return distanceSquared <= this.radius ** 2;
  }
}

// --------------------------------------------------------------------

class SpecialNormalMode {
  constructor(name, phasesDefaultValues) {
    this.name = name;
    this.phasesDefaultValues = phasesDefaultValues;
  }
}

// --------------------------------------------------------------------

class SquareCoordinates extends React.Component {
  constructor(props) {
    super(props);
    this.x = [React.createRef(), React.createRef()];
    this.y = [React.createRef(), React.createRef()];
  }

  render() {
    return (
      <table style={{ display: this.props.display }}>
        <tbody>
          <tr className="oneLineAnnotation">
            <td className="annotation">X1</td>
            <td className="annotation">Y1</td>
            <td className="annotation">X2</td>
            <td className="annotation">Y2</td>
          </tr>
          <tr>
            <td><input type="number" className="geometric" defaultValue="0.33" step="0.01" onChange={this.props.onChange} ref={this.x[0]} /></td>
            <td><input type="number" className="geometric" defaultValue="0.33" step="0.01" onChange={this.props.onChange} ref={this.y[0]} /></td>
            <td><input type="number" className="geometric" defaultValue="0.67" step="0.01" onChange={this.props.onChange} ref={this.x[1]} /></td>
            <td><input type="number" className="geometric" defaultValue="0.67" step="0.01" onChange={this.props.onChange} ref={this.y[1]} /></td>
          </tr>
          <tr className="oneLineAnnotation"><td></td><td></td><td></td><td></td></tr>
        </tbody>
      </table>
    );
  }
}

// --------------------------------------------------------------------

class TeamDeathmatch {
  constructor(battleground, label, coordinates, spawnPoints, maximumMinimapScale, minimapScaleDecrement) {
    this.battleground = battleground;
    this.label = label;
    this.coordinates = coordinates;
    this.spawnPoints = spawnPoints;

    this.vectors = [];

    for (let i = 0; i < this.coordinates.length; i++) {
      let j = (i + 1) % this.coordinates.length;

      this.vectors.push([this.coordinates[j][0] - this.coordinates[i][0],
                         this.coordinates[j][1] - this.coordinates[i][1]]);
    }

    let twiceArea = 0.0;

    this.centroid = [0.0, 0.0];

    for (let i = 0; i < this.coordinates.length; i++) {
      let j = (i + 1) % this.coordinates.length;

      let diff = this.coordinates[i][0] * this.coordinates[j][1] - this.coordinates[i][1] * this.coordinates[j][0];

      twiceArea += diff;

      this.centroid[0] += (this.coordinates[i][0] + this.coordinates[j][0]) * diff;
      this.centroid[1] += (this.coordinates[i][1] + this.coordinates[j][1]) * diff;
    }

    this.centroid[0] /= 3.0 * twiceArea;
    this.centroid[1] /= 3.0 * twiceArea;

    let minX = this.coordinates[0][0];
    let maxX = this.coordinates[0][0];

    let minY = this.coordinates[0][1];
    let maxY = this.coordinates[0][1];

    for (let i = 1; i < this.coordinates.length; i++) {
      minX = Math.min(this.coordinates[i][0], minX);
      maxX = Math.max(this.coordinates[i][0], maxX);

      minY = Math.min(this.coordinates[i][1], minY);
      maxY = Math.max(this.coordinates[i][1], maxY);
    }

    let maxSize = Math.max(maxX - minX, maxY - minY);

    this.minimapScale = maximumMinimapScale;

    while (this.minimapScale > 1.0) {
      if (maxSize * this.minimapScale < 0.9) { break; }

      this.minimapScale -= minimapScaleDecrement(this.minimapScale);
    }

    this.href = "https://lunchmeat.zone/?m=" + battleground + "&ms=" + this.minimapScale.toFixed(1) + "&mx=" + this.centroid[0] + "&my=" + this.centroid[1] + "&s=TDM";
  }

  contained(topLeftX, topLeftY, bottomRightX, bottomRightY) {
    for (let i = 0; i < this.coordinates.length; i++) {
      if ((this.coordinates[i][0] >= topLeftX) && (this.coordinates[i][0] <= bottomRightX) &&
          (this.coordinates[i][1] >= topLeftY) && (this.coordinates[i][1] <= bottomRightY)) {
        return true;
      }
    }

    return false;
  }

  inside(x, y) {
    let numberOfIntersections = 0;

    for (let i = 0; i < this.coordinates.length; i++) {
      if (Math.abs(this.vectors[i][1]) > 0.00001) {
        let vectorCoefficient = (y - this.coordinates[i][1]) / this.vectors[i][1];

        if ((vectorCoefficient >= 0.0) && (vectorCoefficient < 1.0)) {
          let intersectionX = this.coordinates[i][0] + vectorCoefficient * this.vectors[i][0];

          if (x <= intersectionX) { numberOfIntersections++; }
        }
      }
    }

    if ((numberOfIntersections % 2) === 0) {
      return false;
    }

    numberOfIntersections = 0;

    for (let i = 0; i < this.coordinates.length; i++) {
      if (Math.abs(this.vectors[i][0]) > 0.00001) {
        let vectorCoefficient = (x - this.coordinates[i][0]) / this.vectors[i][0];

        if ((vectorCoefficient >= 0.0) && (vectorCoefficient < 1.0)) {
          let intersectionY = this.coordinates[i][1] + vectorCoefficient * this.vectors[i][1];

          if (y <= intersectionY) { numberOfIntersections++; }
        }
      }
    }

    return (numberOfIntersections % 2) === 1;
  }
}

// --------------------------------------------------------------------

class Tooltip extends React.Component {
  render() {
    let tooltip = this.props.coordinate;
    if (this.props.label !== "") { tooltip += "\n" + this.props.label; }

    return (
      <div className="tooltip" style={{ display: this.props.display, visibility: this.props.visibility, position: this.props.position, left: this.props.left, top: this.props.top }}>
        {tooltip}
      </div>
    );
  }
}

// ====================================================================

const tooltipMeasurementRoot = ReactDOMClient.createRoot(document.getElementById('tooltipMeasurement'));

const root = ReactDOMClient.createRoot(document.getElementById('lbze'));
root.render(<BlueZoneElucidator />);
